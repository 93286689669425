import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

const alertSubject = new Subject();
const defaultId = "default-alert";

export const alertType = {
    success: "success",
    error: "error",
    info: "info",
    warning: "warning",
};

const onAlert = (id = defaultId) => {
    return alertSubject.asObservable().pipe(filter(x => x && x.id === id));
};

const success = (message, options) => {
    alert({ ...options, type: alertType.success, message });
};

const error = (message, options) => {
    alert({ ...options, type: alertType.error, message });
};

const info = (message, options) => {
    alert({ ...options, type: alertType.info, message });
};

const warn = (message, options) => {
    alert({ ...options, type: alertType.warning, message });
};

const alert = (alert) => {
    alert.id = alert.id || defaultId;
    alertSubject.next(alert);
};

const clear = (id = defaultId) => {
    alertSubject.next({ id });
};


export const alertService = {
    onAlert,
    success,
    error,
    info,
    warn,
    alert,
    clear,
};
