import reduce from "lodash/reduce";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import metaTagsManager from "Managers/metaTagsManager";
import useEventSystem from "Common/Hooks/useEventSystem";

const AppMetaTags = ({ pathname }) => {
    useEventSystem("SetMetaTags");
    const tags = metaTagsManager.getMetaTags(pathname);

    return (
        <MetaTags>
            {reduce(tags,(result, value, tagName) => {
                const Template = metaTagsManager.getTemplate(tagName);
                result.push(<Template key={tagName} name={tagName} value={value} />);
                return result;
            }, [])}
        </MetaTags>
    );
};

AppMetaTags.propTypes = {
    pathname: PropTypes.string.isRequired,
};

export default AppMetaTags;
