import "./style.scss";
import SharingCard from "Components/SharingCard";
import classnames from "classnames";
import { images, bookMarkImages } from "Common/sharingCardImages";
import Slider from "react-slick";
import { connect } from "react-redux";
import { alertService } from "Services/alertService";
import html2canvas from "html2canvas";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { bookMarkModal, shareToggle } from "../../Redux/actionCreators";
import { useHistory } from "react-router";

const SharingCards = ({ configInputsState, currentTime, onClose, bookMark, bookMarkModal, shareToggle }) => {
    const { combinations } = configInputsState;
    const { activeFields } = combinations;
    const {
        isSimple,
        totalTime,
        totalRounds,
        lockedCombos,
        lockedRoundsCompleted,
        lockedActiveTime,
        lockedTotalActiveTime
    } = currentTime;

    const [ toggleState, setToghleState ] = useState(true);
    const [ currentIndex, setCurrentIndex ] = useState(0);
    const [ selectedImages, setSelectedImages ] = useState([]);
    const slideRef = useRef();
    const refElement = useRef(null);
    const history = useHistory();
    const { search } = history?.location || {};

    useEffect(() => {
        const sortedImages = bookMark ? bookMarkImages : images.sort(() => 0.5 - Math.random());
        setSelectedImages(sortedImages.slice(0, 12));
    }, []);

    useEffect(() => {
        if (bookMark) {
            setTimeout(() => {
                shareHandle();
            }, 500);
        }
    }, [bookMark]);

    useEffect(() => {
        const handleClickOutside = event => {
            if (refElement.current && !refElement.current.contains(event.target)) {
                onClose(false);
                bookMarkModal(false);
                shareToggle(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refElement]);

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    // variableWidth: false,
                    centerPadding: "10px",
                }
            }
        ]
    };
    const onClickHandle = (index) => {
        if (slideRef?.current && currentIndex !== index) {
            slideRef?.current?.slickGoTo(index);
        }
    };

    const shareHandle = () => {
        const node = document.getElementById(`sharing-card-${currentIndex}`);

        html2canvas(node, {backgroundColor: "rgba(0,0,0,0)"})
            .then((canvas) => {
                const dataUrl = canvas.toDataURL("image/jpg");
                let arr = dataUrl.split(","), mime = arr[0].match(/:(.*?);/)[1];
                let bstr = window.atob(arr[1]);
                let n = bstr.length;
                let u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }
                const file = new File([u8arr], "combatgo-rounds.jpg", {type:"image/jpeg"});

                if (navigator.canShare && navigator.canShare({ files: [file] })) {
                    navigator.share({
                        files:  [file],
                        title: bookMark ? `${totalRounds} Round boxing workout on Combat Go` : "Train with Combat Go",
                        url: `https://web.combatgo.app/${search}`,
                        text: bookMark ? `Try this ${totalRounds} round boxing workout with the Combat Go boxing timer and virtual combo coach!` : `I completed a ${lockedRoundsCompleted || 0} round boxing workout with the Combat Go boxing timer and virtual combo coach! Use the free web app to generate custom workouts for shadow boxing and bag/pad work based on your experience level and training preferences. #combatgoapp #boxingtraining`,
                    })
                        .then(() => alertService.success("Share was successful.", { autoClose: true }))
                        .catch((error) => alertService.error("Sharing failed: " + error, { autoClose: true }));
                } else {
                    alertService.error("Sharing note supported. Try taking a screenshot instead.", { autoClose: true });
                }
            })
            .catch((error) => {
                console.error("oops, something went wrong!", error);
            });
    };
    return (
        <div styleName="sharing-cards-wrapper">
            <div styleName={classnames("sharing-cards-modal",{"sharing-cardsBookmar-modal": bookMark} )} ref={refElement}>
                {!bookMark && <><span onClick={onClose} styleName="sharing-cards-modal__close">
                    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.6641 7.5L7.66406 22.5" stroke="#1E1F20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.66406 7.5L22.6641 22.5" stroke="#1E1F20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
                <span styleName="sharing-cards-modal__icon">
                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="80" height="80" rx="40" fill="#8EA94B"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M50.3948 29.3955C50.8846 28.9099 51.5459 28.6367 52.2356 28.6348C52.9253 28.6329 53.588 28.9025 54.0805 29.3853C54.573 29.8682 54.8557 30.5254 54.8674 31.215C54.8792 31.9046 54.6191 32.5712 54.1433 33.0705L40.1713 50.5355C39.9312 50.7941 39.6414 51.0017 39.3192 51.1458C38.997 51.2898 38.6491 51.3675 38.2962 51.374C37.9433 51.3805 37.5928 51.3158 37.2655 51.1838C36.9382 51.0518 36.6409 50.8551 36.3913 50.6055L27.1338 41.3445C26.8759 41.1042 26.6691 40.8144 26.5256 40.4924C26.3821 40.1704 26.305 39.8228 26.2987 39.4703C26.2925 39.1179 26.3574 38.7678 26.4894 38.4409C26.6214 38.114 26.8179 37.8171 27.0672 37.5679C27.3165 37.3186 27.6134 37.1221 27.9402 36.9901C28.2671 36.858 28.6172 36.7932 28.9696 36.7994C29.3221 36.8056 29.6697 36.8828 29.9917 37.0263C30.3137 37.1697 30.6035 37.3766 30.8438 37.6345L38.1728 44.96L50.3283 29.4725C50.35 29.4454 50.3734 29.4197 50.3983 29.3955H50.3948Z" fill="white"/>
                        <path opacity="0.16" d="M0 32C0 14.3269 14.3269 0 32 0H50V34C50 42.8366 42.8366 50 34 50H0V32Z" fill="white"/>
                    </svg>
                </span>
                {
                    totalRounds === lockedRoundsCompleted ? (
                        <h3 styleName="sharing-cards-modal__heading">Congratulations!</h3>
                    ) : (
                        <h3 styleName="sharing-cards-modal__heading">Nice Job!</h3>
                    )
                }
                <p styleName="sharing-cards-modal__text">You just completed a {lockedRoundsCompleted} round boxing workout with Combat Go.</p></>}
                {!bookMark ? (
                    <Slider ref={slideRef} beforeChange={(_, index) => setCurrentIndex(index)} {...settings}>
                        {
                            selectedImages?.length > 0 && selectedImages.slice(0, 12).map((image, index) => (
                                <SharingCard
                                    key={image}
                                    index={index}
                                    imageSrc={image}
                                    bookMark={bookMark}
                                    type={ toggleState ? 2 : 1}
                                    shareHandle={shareHandle}
                                    onClickHandle={onClickHandle}
                                    values={activeFields}
                                    isSimple={isSimple}
                                    roundsSpent={lockedRoundsCompleted}
                                    activeTime={lockedActiveTime}
                                    lockedCombos={lockedCombos}
                                    totalTime={lockedTotalActiveTime}
                                />
                            ))
                        }
                    </Slider>
                ) : (
                    selectedImages?.length > 0 && selectedImages.map((image, index) => (
                        <SharingCard
                            key={image}
                            index={index}
                            imageSrc={image}
                            bookMark={bookMark}
                            type={ toggleState ? 2 : 1}
                            shareHandle={shareHandle}
                            onClickHandle={onClickHandle}
                            values={activeFields}
                            isSimple={isSimple}
                            roundsSpent={totalRounds}
                            activeTime={lockedActiveTime}
                            lockedCombos={lockedCombos}
                            totalTime={totalTime}
                        />
                    ))
                )}

                {!bookMark && <div styleName="sharing-cards-modal__action">
                    <button onClick={() => setToghleState(!toggleState)} className="btn-primary" styleName="btn-primary--toggle">Toggle Stats</button>
                    <button onClick={shareHandle} className="btn-primary" styleName="btn-primary--share">Share</button>
                </div>}
            </div>
        </div>
    );
};

const mapStateToProp = state => {
    return {
        currentTime: state.currentTime,
        configInputsState: state.configInputs
    };
};


const mapDispatchToProps = dispatch => {
    return {
        bookMarkModal: (toggle) => dispatch(bookMarkModal(toggle)),
        shareToggle: (toggle) => dispatch(shareToggle(toggle))
    };
};

export default connect(mapStateToProp, mapDispatchToProps)(SharingCards);
