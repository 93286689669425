// PUBLIC PAGES
import Home from "Pages/Home";
import ConfigWorkoutView from "Pages/ConfigWorkoutView";
import PreviewCombosView from "Pages/PreviewCombosView";
import BoxingTechniquePrimerView from "Pages/BoxingTechniquePrimerView";

const routes = [
    {
        path: "/",
        component: Home,
        isPrivate: false,
        isHeader: true
    },
    {
        path: "/config",
        component: ConfigWorkoutView,
        title: "Configure Workout",
        backPath: "/",
        isPrivate: false
    },
    {
        path: "/combos",
        component: PreviewCombosView,
        title: "Preview Combos",
        backPath: "/",
        isPrivate: false
    },
    {
        path: "/primer",
        component: BoxingTechniquePrimerView,
        title: "Boxing Technique Primer",
        backPath: "/",
        isPrivate: false
    },
    {
        path: "/primer/:combo",
        component: BoxingTechniquePrimerView,
        title: "fetching...",
        backPath: "/combos",
        isPrivate: false
    }
];

export default routes;
