import natsort from "natsort";
var sorter = natsort({insensitive: true});

export const sortArray = (arr, p) => arr.slice(0).sort((a, b) => (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0);

export const searchArray = (arr, q, p) => arr.filter(ar => ar["shortName"].toLowerCase().includes(q.toLowerCase()) || ar["longName"].toLowerCase().includes(q.toLowerCase()));

export const getTime = time => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 60000) % 60);

    return {minutes, seconds};
};

export const totalSeconds = time => (time?.minutes || 0) * 60 + (time?.seconds || 0);

export const isMobile = () => window.innerWidth < 1024;

export const sortArrayWithNaturalSort = (arr, p) => arr.sort((a, b) => sorter(a[p], b[p]));
