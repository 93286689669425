import "./style.scss";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { alertService } from "Services/alertService";
import { useState, useEffect, useCallback } from "react";

const Alert = ({ id, fade }) => {
    const history = useHistory();
    const [alerts, setAlerts] = useState([]);

    const removeAlert = useCallback(alert => {
        if (fade) {
            const alertWithFade = { ...alert, fade: true };
            setAlerts(alerts => alerts.map(x => x === alert ? alertWithFade : x));
            setTimeout(() => {
                setAlerts(alerts => alerts.filter(x => x !== alertWithFade));
            }, 250);
        } else {
            setAlerts(alerts => alerts.filter(x => x !== alert));
        }
    }, [fade]);

    useEffect(() => {
        const subscription = alertService.onAlert(id);
        subscription.subscribe(alert => {
            if (!alert.message) {
                setAlerts(alerts => {
                    const filteredAlerts = alerts.filter(x => x.keepAfterRouteChange);
                    filteredAlerts.forEach(x => delete x.keepAfterRouteChange);
                    return filteredAlerts;
                });
            } else {
                setAlerts(alerts => {
                    const filteredAlerts = alerts.filter(x => x.message !== alert.message);
                    return [... filteredAlerts, alert];
                });
                if (alert.autoClose) {
                    setTimeout(() => removeAlert(alert), 2000);
                }
            }
        });

        const historyUnlisten = history.listen(() => {
            alertService.clear(id);
        });
        if (subscription && subscription.unsubscribe) {
            return () => {
                subscription.unsubscribe();
                historyUnlisten();
            };
        }
    }, [history, id, removeAlert]);

    const getAlertIcon = type => {
        let icon = "fa-times";

        switch (type) {
            case "success":
                icon = "fa-check";
                break;

            case "info":
                icon = "fa-info-circle";
                break;

            case "warning":
                icon = "fa-info-circle";
                break;

            case "error":
                icon = "fa-times";
                break;
        }

        return icon;
    };

    return (
        <section styleName="alert-messages">
            {alerts.map((alert, index) =>
                <div
                    key={index}
                    styleName={classNames(
                        "alert-message",
                        `alert-message--${alert.type}`
                    )}
                    onClick={() => removeAlert(alert)}
                >
                    <p styleName="alert-message__text">
                        <i
                            styleName="alert-message__icon"
                            className={classNames("fas", getAlertIcon(alert.type))}
                        />
                        {alert.message}
                    </p>
                </div>
            )}
        </section>
    );
};

Alert.defaultProps = {
    id: "default-alert",
    fade: true,
};

Alert.propTypes = {
    id: PropTypes.string,
    fade: PropTypes.bool,
};

export default Alert;
