import { TOGGLE_NAVIGATION, PLAY_AUDIO, ABOUT_MODAL, CURRENT_THEME } from "../actionTypes";

export const toggleNavigation = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_NAVIGATION: {
            return action.payload;
        }
        default:
            return state;
    }
};

export const playAudio = (state = true, action) => {
    switch (action.type) {
        case PLAY_AUDIO:
            return action.payload;
        default:
            return state;
    }
};

export const aboutModal = (state = false, action) => {
    switch (action.type) {
        case ABOUT_MODAL:
            return action.payload;
        default:
            return state;
    }
};

export const theme = (state = false, action) => {
    switch (action.type) {
        case CURRENT_THEME: {
            const theme = action.payload ? "light" : "dark";
            localStorage.setItem("theme", theme);

            return theme;
        }
        default: {
            let theme = localStorage.getItem("theme");
            if(!theme) {
                const isDark = window.matchMedia("(prefers-color-scheme: dark)");

                theme = isDark.matches ? "dark" : "light";
                localStorage.setItem("theme", theme);
            }
            return theme;
        }
    }
};
