import "./style.scss";
import BoxingTechniquePrimer from "Components/BoxingTechniquePrimer";

const BoxingTechniquePrimerView = ({ computedMatch, location }) => {
    const { params } = computedMatch;
    const { search } = location;

    return (
        <BoxingTechniquePrimer location={params.combo || false} query={search} />
    );
};

export default BoxingTechniquePrimerView;
