import "./style.scss";
import SearchForm from "Components/SearchForm";
import ReactPlayer from "react-player";
import Loader from "Components/Loader";
import { connect } from "react-redux";
import SearchSuggestion from "Components/SearchSuggestion";
import { selectSearchSuggestion, getBoxingPrimer, getBoxingPrimerMove, initConfigInputs } from "Redux/actionCreators";
import { useEffect, useState } from "react";
import { sortArrayWithNaturalSort, searchArray } from "Common/helpers";

const searchSuggestions = [
    {
        key: "punches",
        name: "Punches",
        searchName: "Punch"
    },
    {
        key: "defense",
        name: "Defense",
        searchName: "Defense"
    },
    {
        key: "footwork",
        name: "Footwork",
        searchName: "Footwork"
    },
    {
        key: "kicks",
        name: "Kicks",
        searchName: "Kick"
    },
    {
        key: "elbow",
        name: "Knees & Elbows",
        searchName: "Knees & Elbows"
    }
];

const BoxingTechniquePrimer = ({
    query,
    location,
    timerResponse,
    getBoxingPrimer,
    initConfigInputs,
    boxingPrimerState,
    getBoxingPrimerMove,
    searchSuggestionState,
    selectSearchSuggestion,
    currentPrimerMoveState
}) => {
    const [primer, setPrimer] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [search, setSearch] = useState("");

    useEffect(() => {
        if (timerResponse === "TIMER_RESPONSE_INIT") initConfigInputs();
        getBoxingPrimer();

        if (location) {
            getBoxingPrimerMove(location);
        } else if (query) {
            const queryStrings = new URLSearchParams(query);
            const categories = queryStrings.getAll("category");
            const move = queryStrings.get("move");
            if (categories?.length) {
                categories.map(category => {
                    const getCategory = searchSuggestions.find(suggestion => suggestion.key === category);
                    if (getCategory) {
                        selectSearchSuggestion(getCategory.searchName);
                    }
                });
            }
            if (move && move !== "") {
                setSearch(move);
            }
        }
        return () => {
            setFetched(false);
            getBoxingPrimerMove(false);
        };
    }, [location, query]);

    useEffect(() => {
        if (!fetched) {
            if (boxingPrimerState && currentPrimerMoveState) {
                const boxingPrimers = boxingPrimerState.filter(primer => currentPrimerMoveState.stepIds?.includes(primer.id));
                setFetched(true);
                setPrimer(sortArrayWithNaturalSort(boxingPrimers, "shortName"));
            } else {
                if (boxingPrimerState) {
                    setPrimer(sortArrayWithNaturalSort(boxingPrimerState, "shortName"));
                }
            }
        }
    }, [boxingPrimerState, currentPrimerMoveState]);

    useEffect(() => {
        if (boxingPrimerState) {
            const filtered = boxingPrimerState.filter(prime => searchSuggestionState.includes(prime.type));
            const tempPrimer = filtered.length ? filtered : boxingPrimerState;
            if (search !== "") {
                const searched = searchArray(tempPrimer, search, "shortName");
                setPrimer(sortArrayWithNaturalSort(searched, "shortName"));
            } else {
                setPrimer(sortArrayWithNaturalSort(tempPrimer, "shortName"));
            }
        }
    }, [searchSuggestionState, search, boxingPrimerState]);

    return (
        <section>
            <div className="container">
                {
                    !location && (
                        <form>
                            <SearchForm
                                name="move"
                                value={search}
                                onChange={setSearch}
                            />
                            <SearchSuggestion
                                searchSuggestions={searchSuggestions}
                                selectedSuggestions={searchSuggestionState}
                            />
                        </form>
                    )
                }
                <div styleName="posts">
                    {
                        primer ? (primer?.length ? (
                            searchSuggestions && searchSuggestions.map(suggestion => (
                                primer.map(prime => prime.type === suggestion.searchName && (
                                    <div key={prime.shortName} styleName="post">
                                        <div styleName="post__row">
                                            <h3 styleName="post__name">{prime.longName}</h3>
                                            <span styleName="post__tag">{prime.type}</span>
                                        </div>
                                        <p styleName="post__text" dangerouslySetInnerHTML={{ __html: prime.description }} />
                                        {
                                            prime.medias && (
                                                prime.medias.map(media => (
                                                    <div styleName="post__video" key={media.url}>
                                                        {
                                                            // Quick fix. Should be VIDEO once BE changes are made.
                                                            media.url && media.type != "IMAGE" && (
                                                                <ReactPlayer height="400px" width="100%" url={media.url} />
                                                            )
                                                        }
                                                        {
                                                            media.type === "IMAGE" && (
                                                                <img src={media.url} />
                                                            )
                                                        }
                                                    </div>
                                                ))
                                            )
                                        }
                                    </div>
                                ))
                            ))
                        ) : <p>Not Found</p>) : <Loader />
                    }
                </div>
            </div>
        </section>
    );
};

const mapStateToProp = state => ({
    boxingPrimerState: state.boxingPrimer,
    searchSuggestionState: state.searchSuggestion,
    currentPrimerMoveState: state.currentPrimerMove,
    timerResponse: state.timerResponse
});

const mapDispatchToProps = dispatch => ({
    getBoxingPrimer: data => dispatch(getBoxingPrimer(data)),
    getBoxingPrimerMove: data => dispatch(getBoxingPrimerMove(data)),
    initConfigInputs: () => dispatch(initConfigInputs()),
    selectSearchSuggestion: data => dispatch(selectSearchSuggestion(data))
});

export default connect(mapStateToProp, mapDispatchToProps)(BoxingTechniquePrimer);
