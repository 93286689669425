const newPath = process.env.MEDIA_URL;
const localPath = "/public/assets/img/";

const getAsset = name => newPath + name;
const getLocalAsset = name => localPath + name;

const isActive = path => window.location.pathname === path;

export {
    isActive,
    getAsset,
    getLocalAsset
};
