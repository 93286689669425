const rules = [
    {
        test: /.*/,
        tags: {
            "title|og:title|twitter:title": "Combat Go",
            "description|og:description|twitter:description": "",
            "publisher": "",
            "fb:app_id": "1829935823906294",
            "og:locale": "en_US",
            "og:site_name": "Boxing",
            "og:type": "website",
            "og:url|canonical": ({pathname}) => pathname,
            "og:image|twitter:image": "",
            "og:image:secure_url": "",
            "twitter:card": "summary",
            "twitter:creator": "@Boxing",
            "twitter:site": "@Boxing",
        },
    },
    {
        test: /^\/stopwatch$/,
        tags: {
            "title|og:title|twitter:title": "Stopwatch",
        },
    },
];

export default rules;
