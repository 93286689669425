import "./style.scss";
import ConfigureForm from "Components/ConfigureForm";

const ConfigWorkoutView = () => {
    return (
        <ConfigureForm />
    );
};

export default ConfigWorkoutView;
