import "./style.scss";
import { connect } from "react-redux";
import classNames from "classnames";
import { selectSearchSuggestion } from "Redux/actionCreators";

const SearchSuggestion = ({ searchSuggestions, selectedSuggestions, selectSearchSuggestion }) => {
    return (
        <div styleName="search-suggestions">
            {
                searchSuggestions.map(searchSuggestion => (
                    <span onClick={() => selectSearchSuggestion(searchSuggestion.searchName)} key={searchSuggestion.key} styleName={classNames("search-suggestions__suggestion", {
                        "search-suggestions__suggestion--active": selectedSuggestions?.length && selectedSuggestions.includes(searchSuggestion.searchName)
                    })}>{searchSuggestion.name}</span>
                ))
            }
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    selectSearchSuggestion: data => dispatch(selectSearchSuggestion(data))
});

export default connect(null, mapDispatchToProps)(SearchSuggestion);
