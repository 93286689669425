import { useState } from "react";
import { connect } from "react-redux";
import { saveConfigInputs } from "Redux/actionCreators";
import "./style.scss";

const BookMarkModal = ({ open, onClose, onConfirm, onShare, saveConfigInputs, shareToggle, bookMark }) => {
    const confirm = () => {
        onConfirm();
        onClose(!open);
    };
    return (
        <div styleName="bookmark-modal--popup">
            <div styleName="bookmark-modal">
                {!shareToggle ? <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="80" height="80" rx="40" fill="#2974A3"/>
                    <mask id="mask0_4068_12643" maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="80">
                        <rect width="80" height="80" rx="40" fill="#8EA94B"/>
                    </mask>
                    <g mask="url(#mask0_4068_12643)">
                        <path opacity="0.16" d="M0 30H34C42.8366 30 50 37.1634 50 46V80H0V30Z" fill="#2974A3"/>
                    </g>
                    <path d="M59.1673 28.0371C59.1673 29.1829 58.2298 30.1204 57.084 30.1204H55.0006V32.2038C55.0006 33.3496 54.0631 34.2871 52.9173 34.2871C51.7715 34.2871 50.834 33.3496 50.834 32.2038V30.1204H48.7506C47.6048 30.1204 46.6673 29.1829 46.6673 28.0371C46.6673 26.8913 47.6048 25.9538 48.7506 25.9538H50.834V23.8704C50.834 22.7246 51.7715 21.7871 52.9173 21.7871C54.0631 21.7871 55.0006 22.7246 55.0006 23.8704V25.9538H57.084C58.2298 25.9538 59.1673 26.8913 59.1673 28.0371ZM55.0006 56.1204C55.0006 57.6204 53.4798 58.6204 52.1048 58.0371L40.4173 53.0371L28.7298 58.0371C27.3548 58.6413 25.834 57.6204 25.834 56.1204V25.9538C25.834 23.6621 27.709 21.7871 30.0007 21.7871H44.584C43.2715 23.5371 42.5006 25.6829 42.5006 28.0371C42.5006 33.7871 47.1673 38.4538 52.9173 38.4538C53.6256 38.4538 54.334 38.3913 55.0006 38.2454V56.1204Z" fill="white"/>
                </svg>:<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="80" height="80" rx="40" fill="#2974A3"/>
                    <mask id="mask0_4068_12672"  maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="80">
                        <rect width="80" height="80" rx="40" fill="#8EA94B"/>
                    </mask>
                    <g mask="url(#mask0_4068_12672)">
                        <path opacity="0.16" d="M0 30H34C42.8366 30 50 37.1634 50 46V80H0V30Z" fill="white"/>
                    </g>
                    <path d="M26.9141 25.9538C26.9141 23.6621 28.7891 21.7871 31.0807 21.7871H45.6641C44.3516 23.5371 43.5807 25.6829 43.5807 28.0371C43.5807 33.7871 48.2474 38.4538 53.9974 38.4538C54.7057 38.4538 55.4141 38.3913 56.0807 38.2454V56.1204C56.0807 57.6204 54.5599 58.6204 53.1849 58.0371L41.4974 53.0371L29.8099 58.0371C28.4349 58.6413 26.9141 57.6204 26.9141 56.1204V25.9538ZM62.4766 22.4954C63.2891 23.3079 63.2891 24.6204 62.4766 25.4329L55.1016 32.8079C54.2891 33.6204 52.9766 33.6204 52.1641 32.8079L49.2266 29.8704C48.4141 29.0579 48.4141 27.7454 49.2266 26.9329C50.0391 26.1204 51.3516 26.1204 52.1641 26.9329L53.6432 28.4121L59.5391 22.5163C60.3516 21.6829 61.6641 21.6829 62.4766 22.4954Z" fill="white"/>
                </svg>}
                {!shareToggle ?(<>
                    <p>You can save the current configuration to allow a quick return in the future.</p>
                    <p>This will create a unique URL that you can bookmark or share with friends.</p>
                </>):(<>
                    <p>We have now created a unique URL for your workout. Bookmark this page using your browser tools (keyboard shortcut Ctrl+D) or share it with friends or to social media.</p>
                </>)}

                <div styleName="bookmark-modal__actions">
                    {!shareToggle ? (<>
                        <button onClick={() => onClose(!open)} styleName="bookmark-modal__action-btn">Cancel</button>
                        <button onClick={() =>  saveConfigInputs() } styleName="bookmark-modal__action-btn bookmark-modal__action-btn--confirm">Proceed</button>
                    </>):(<>
                        <button onClick={() => onClose(!open)} styleName="bookmark-modal__action-btn">Close</button>
                        <button onClick={() => { onShare(true); onClose(false); bookMark(true);}} styleName="bookmark-modal__action-btn bookmark-modal__action-btn--confirm">share</button>
                    </>)}
                </div>
            </div>
        </div>
    );
};


const mapDispatchToProps = dispatch => ({
    saveConfigInputs: () => dispatch(saveConfigInputs())
});


const mapStateToProps = state => ({
    shareToggle: state.shareToggleReducer
});

export default connect(mapStateToProps, mapDispatchToProps)(BookMarkModal);
