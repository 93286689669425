import Api from "./Api";

export const get = async (path, data, isZendesk = false) => {
    const response = await Api(isZendesk).get(path, {
        params: data
    });

    return response;
};

export const post = async (path, data) => {
    const response = await Api().post(path, data);

    return response;
};
