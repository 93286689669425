import "./style.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { connect } from "react-redux";
import { useWindowSize } from "Common/Hooks/useWindowSize";

const BoxingWorkout = ({ currentTime, configInputsState, configWorkout }) => {
    const [isWorkoutActive, setWorkoutActive] = useState(false);

    const size = useWindowSize();

    const { innerHeight: height } = size;
    const {
        statusRound,
        totalRounds,
        isStopped,
        isfocued,
        isSimple,
        nextStatusRound,
        moveMultiplier
    } = currentTime;

    const isLongScreen = innerHeight > 600;
    const { combinations } = configInputsState;
    const activeTab = configWorkout.find(tab => tab.isActive);

    const { activeFields } = combinations;

    return (
        <section styleName="boxing-workout-section">
            <div styleName={classNames("boxing-workout-dropdown", {
                "boxing-workout-dropdown--absolute": size.isMobile,
                "boxing-workout-dropdown--padding": isLongScreen,
                "boxing-workout-dropdown--no-padding": (!isLongScreen && !isStopped),
            })}>
                <div styleName="boxing-workout__wrap">
                    {
                        (isStopped && !isfocued) ? (
                            <>
                                <span
                                    styleName="boxing-workout__text"
                                >
                                    {
                                        size.isMobile
                                            ? `${totalRounds} Round${totalRounds > 1 ? "s" : ""} Boxing Workout`
                                            : `${isSimple ? "Timer:" :  "Combos:"} ${totalRounds} Round${totalRounds > 1 ? "s" : ""}`
                                    }
                                </span>
                                <Link to="/config" styleName="boxing-workout__config"><i className="fal fa-cog"></i></Link>
                            </>
                        ) : (
                            <div styleName="boxing-workout__wrap-text">
                                {
                                    isLongScreen && <span styleName="boxing-workout__now">Now</span>
                                }
                                <span
                                    styleName="boxing-workout__status"
                                >
                                    {
                                        (moveMultiplier !== 0) && (
                                            <span styleName="boxing-workout__mulitplier">
                                                {moveMultiplier}x
                                            </span>
                                        )
                                    }
                                    { statusRound }
                                </span>
                            </div>
                        )
                    }
                </div>
                <Link to="/config" styleName={classNames("boxing-workouts", {
                    "active": ((isWorkoutActive && isStopped) || isLongScreen) || !size.isMobile
                })}>
                    {
                        ((!isStopped && isLongScreen) || isfocued)
                            ? (
                                <div styleName="running-timer__content">
                                    <span styleName="boxing-workout__next">Next Up</span>
                                    <span
                                        styleName="boxing-workout__text"
                                    >
                                        {nextStatusRound}
                                    </span>
                                </div>
                            )
                            : (
                                activeTab.key === "combinations" && (
                                    <>
                                        {
                                            combinations.otherInputs && combinations.otherInputs.map(otherInput => (
                                                otherInput.type === "select" && (
                                                    <ul key={otherInput.key} styleName="boxing-workouts__wrap">
                                                        {
                                                            otherInput.options?.length && otherInput.options.map(work => (
                                                                <li key={work.key}
                                                                    styleName={classNames("boxing-workout", {
                                                                        "boxing-workout--more": activeFields[otherInput.key][work.key] === "More",
                                                                        "boxing-workout--some": activeFields[otherInput.key][work.key] === "Some"
                                                                    })}
                                                                >
                                                                    {size.isMobile ? (work.secondaryTitle || work.title) : work.title}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                )
                                            ))
                                        }
                                        <ul styleName="boxing-workouts__wrap">
                                            {
                                                combinations.otherInputs && combinations.otherInputs.map(otherInput => (
                                                    (otherInput.type !== "select" && otherInput.featured) && (
                                                        otherInput.options?.length && otherInput.options.map(work => (
                                                            (activeFields[otherInput.key] === work.key) && (
                                                                <li key={work.key}
                                                                    styleName="boxing-workout"
                                                                >
                                                                    {
                                                                        otherInput.icon && <span styleName="boxing-workout__icon" dangerouslySetInnerHTML={{ __html: otherInput.icon }} />
                                                                    }
                                                                    {size.isMobile ? (work.secondaryTitle || work.title) : work.title}
                                                                </li>
                                                            )
                                                        ))
                                                    )
                                                ))
                                            }
                                        </ul>
                                    </>
                                )
                            )
                    }
                </Link>
                {
                    ((isStopped && !isLongScreen) && size.isMobile) && (
                        <span
                            onClick={() => setWorkoutActive(!isWorkoutActive)}
                            styleName={classNames("boxing-button", { "boxing-button--active": isWorkoutActive })}></span>
                    )
                }
            </div>
        </section>
    );
};

const mapStateToProp = state => {
    return {
        currentTime: state.currentTime,
        configInputsState: state.configInputs,
        configWorkout: state.configWorkout
    };
};

export default connect(mapStateToProp, null)(BoxingWorkout);
