import { CONFIG_INPUTS_UPDATE, RESET_INPUT_FIELDS } from "../actionTypes";
import { inputs, tabs } from "Common/configInputs";

const initialState = inputs;

export const configInputs = (state = initialState, action) => {
    switch (action.type) {
        case CONFIG_INPUTS_UPDATE: {
            const { activeFields } = action.payload;
            const data = state;
            tabs.map(tab => {
                data[tab.key] = {
                    ...data[tab.key],
                    activeFields
                };
            });

            return {
                ...state,
                ...data
            };
        }
        case RESET_INPUT_FIELDS: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state;
    }
};
