import "./style.scss";
import classNames from "classnames";
import { getLocalAsset } from "Common/url";
import { connect } from "react-redux";
import Loader from "Components/Loader";
import { aboutText } from "Redux/actionCreators";
import { useEffect, useRef, useState } from "react";

const OnBoardingModal = ({ open, onClose, aboutText, aboutModalData }) => {
    const refElement = useRef(null);
    const [reminder, setReminder] = useState(false);
    const confirm = () => {
        onClose(reminder);
    };

    useEffect(() => {
        if (aboutModalData.status !== "success") aboutText();
    }, []);

    useEffect(() => {
        const handleClickOutside = event => {
            if (refElement.current && !refElement.current.contains(event.target)) {
                confirm();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refElement, open, confirm]);

    return (
        <aside styleName={classNames("mobilemenu", { "in": open })}>
            <div styleName="mobilemenu__inner" ref={refElement}>
                <a href="https://www.combatgo.app/" target="_blank" rel="noreferrer" styleName="branding">
                    <img styleName="branding__img" src={getLocalAsset("logo.png")} alt="logo" />
                    <span styleName="branding__name">COMBAT<span styleName="branding__by">GO</span></span>
                </a>
                {
                    aboutModalData.status === "success" ? (
                        <div styleName="mobilemenu__content">
                            <div dangerouslySetInnerHTML={{__html: aboutModalData.data}} />
                            <label styleName="message">
                                <input value={reminder} onChange={() => setReminder(!reminder)} type="checkbox" />
                                <span>Do not show again for 7 days.</span>
                            </label>
                        </div>
                    ) : <Loader />
                }
            </div>
            <a onClick={() => confirm()} styleName="mobilemenu__close">&times;</a>
        </aside>
    );
};

const mapStateToProp = state => {
    return {
        aboutModalData: state.aboutModalData
    };
};

const mapDispatchToProps = dispatch => ({
    aboutText: () => dispatch(aboutText())
});

export default connect(mapStateToProp, mapDispatchToProps)(OnBoardingModal);
