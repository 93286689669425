import {
    INIT_TIME,
    RUNNING_TIME,
    START_TIME,
    CURRENT_TIME,
    STOP_TIME,
    NEXT_STEP,
    UPDATE_SIZE,
    UPDATE_TIME,
    WORK_OUT_ENDED,
    TIMER_RESPONSE_SUCCESS,
    TIMER_RESPONSE_WORKING,
    COMBO_GENRATE_SUCCESS
} from "../actionTypes";

let initialState = {
    statusRound: false,
    stepTime: 30000,
    totalTime: 30000,
    rounds: [],
    comboMove: {},
    currentRound: 1,
    totalRounds: 5,
    total: 30000,
    currentMove: 0,
    move: 0,
    currentUIMove: 0,
    totalUIMoves: 0,
    medias: null,
    isSimple: false,
    innerDashOffset: 0,
    totalCombos: 0,
    currentCombo: 0,
    dashOffset: 0,
    dashArray: 0,
    roundsSpent: 1,
    roundsCompleted: 1,
    innerDashArray: 0,
    isStopped: true,
    isForcedStoped: false,
    isCompletedWorkout: false,
    isfocued: false
};

export const currentTime = (state = initialState, action) => {
    switch (action.type) {
        case INIT_TIME: {

            return {
                ...state,
                ...action.payload
            };
        }

        case UPDATE_TIME: {
            let { rounds, comboMove, request } = action.payload;
            let { currentRound, currentMove } = state;
            const totalRounds = rounds.length;

            currentRound = 0;
            currentMove = 0;

            const totalCombos = request.stimer ? 0 : getTotalCombos(rounds);
            const totalUIMoves = request.stimer ? 0 : getTotalMoves(rounds);

            const prepairRound = {
                moves: [
                    rounds[currentRound]?.moves[currentMove],
                    rounds[currentRound]?.moves[currentMove + 1]
                ],
                round: 0,
                prepare: true,
                name: "Prepare-Round"
            };
            const restRound = {
                moves: [
                    rounds[currentRound]?.moves[rounds[currentRound]?.moves.length - 2],
                    rounds[currentRound]?.moves[rounds[currentRound]?.moves.length - 1]
                ],
                rest: true,
                round: "Rest-Round",
                name: "Rest-Round"
            };

            rounds[currentRound].moves.shift();
            rounds[currentRound].moves.shift();
            rounds.unshift(prepairRound);

            const totalTime = getTotalTime(rounds);

            for (let index = 0; index < rounds.length; index++) {
                rounds[index] = {
                    ...rounds[index],
                    moves: rounds[index]?.moves.filter(move => move.stype !== "WARN-REST-END" && move.stype !== "OTHER-REST")
                };
            }

            for (let index = 1; index < rounds.length; index++) if (index % 2 === 0) rounds.splice(index, 0, {...restRound, name: `${restRound.name}-${index}`});

            const round = rounds[currentRound];
            const statusRound = round.moves[currentMove]?.shortName;
            const nextStatusRound = rounds[currentRound + 1].moves[currentMove + 1]?.shortName;

            const move = round.moves[currentMove].duration;
            const stepTotalTime = getStepTotalTime(round.moves);

            const dashArray = state.radius * Math.PI * 2;
            const dashInnerArray = state.innerRadius * Math.PI * 2;

            const calculateStepPercentage = (stepTotalTime / stepTotalTime) * 100;
            const calculateTotalPercentage = (totalTime / totalTime) * 100;

            initialState = {
                ...state,
                rounds,
                round,
                nextStatusRound,
                statusRound,
                totalTime,
                comboMove,
                currentMove: 0,
                move,
                currentRound: 0,
                roundsSpent: 1,
                moveMultiplier: 0,
                currentUIMove: 0,
                totalUIMoves,
                totalCombos,
                currentCombo: 0,
                totalRounds,
                nextMedia: false,
                innerDashOffset: dashInnerArray - dashInnerArray * calculateStepPercentage / 100,
                dashOffset: dashArray - dashArray * calculateTotalPercentage / 100,
                stepTime: stepTotalTime,
                stepTotalTime,
                activeTime: 0,
                totalActiveTime: 0,
                isSimple: request.stimer,
                roundEndWarn: true,
                prepareStart: true,
                workoutEnded: false,
                roundStart: true,
                isForcedStoped: false,
                isCompletedWorkout: false,
                roundEnd: true,
                total: totalTime,
                isStopped: true
            };

            return {
                ...state,
                rounds,
                round,
                nextStatusRound,
                isSimple: request.stimer,
                statusRound,
                totalTime,
                comboMove,
                activeTime: 0,
                totalActiveTime: 0,
                currentMove,
                move,
                moveMultiplier: 0,
                currentUIMove: 0,
                currentCombo: 0,
                totalUIMoves,
                currentRound,
                roundsSpent: 1,
                roundsCompleted: 1,
                totalRounds,
                totalCombos,
                nextMedia: false,
                innerDashOffset: dashInnerArray - dashInnerArray * calculateStepPercentage / 100,
                dashOffset: dashArray - dashArray * calculateTotalPercentage / 100,
                stepTime: stepTotalTime,
                stepTotalTime,
                roundEndWarn: true,
                prepareStart: true,
                workoutEnded: false,
                roundStart: true,
                roundEnd: true,
                total: totalTime,
                isStopped: true,
                isForcedStoped: false,
                isCompletedWorkout: false,
            };
        }

        case UPDATE_SIZE: {
            return {
                ...state,
                ...action.payload
            };
        }

        case WORK_OUT_ENDED: {
            return {
                ...state,
                workoutEnded: false
            };
        }

        case START_TIME: {
            const {
                currentCombo,
                totalActiveTime,
                activeTime,
                roundsCompleted,
                totalTime,
                stepTime,
                move
            } = state;

            if (totalTime < 0) return initialState;
            let currentMoveDeadline = false;

            const totalTimes = new Date();
            const totalDeadline = new Date(totalTimes.getTime() + totalTime);
            const stepDeadline = new Date(totalTimes.getTime() + stepTime);

            currentMoveDeadline = new Date(totalTimes.getTime() + move);

            return {
                ...state,
                stepDeadline,
                isCompletedWorkout: false,
                currentMoveDeadline,
                lockedRoundsCompleted: roundsCompleted,
                totalDeadline,
                isfocued: true,
                lockedActiveTime: activeTime,
                lockedTotalActiveTime: totalActiveTime,
                lockedCombos: currentCombo,
                isStopped: !state.isStopped
            };
        }

        case RUNNING_TIME: {
            const { isStopped } = state;

            if (!isStopped) {
                const { totalDeadline, stepDeadline } = state;
                let { currentMoveDeadline, move } = state;

                const now = new Date();

                const time = totalDeadline.getTime() - now.getTime();
                let step = stepDeadline.getTime() - now.getTime();
                move = currentMoveDeadline.getTime() - now.getTime();

                if (time < 0) {
                    return {
                        ...initialState,
                        isCompletedWorkout: true,
                        lockedActiveTime: state.activeTime + state.stepTotalTime,
                        lockedTotalActiveTime: state.totalActiveTime + state.stepTotalTime,
                        lockedCombos: state.currentCombo,
                        lockedRoundsCompleted: state.roundsCompleted
                    };
                } else {
                    const totalTimes = new Date();
                    if (step < 0) {
                        let {
                            currentMove,
                            currentMoveDeadline,
                            currentRound,
                            stepTotalTime,
                            statusRound,
                            moveMultiplier,
                            roundsCompleted,
                            roundsSpent,
                            activeTime,
                            totalActiveTime,
                            medias,
                            currentUIMove,
                            currentCombo,
                            move,
                            comboMove,
                            nextStatusRound
                        } = state;
                        totalActiveTime = totalActiveTime ? totalActiveTime + stepTotalTime : stepTotalTime;
                        if (!state.round.name.includes("Prepare-Round") && !state.round.name.includes("Rest-Round")) {
                            roundsSpent += 1;
                            activeTime = activeTime ? activeTime + stepTotalTime : stepTotalTime;
                            roundsCompleted += 1;
                        }

                        currentMove = 1;
                        currentRound += 1;
                        const round = state.rounds[currentRound];
                        statusRound = round?.moves[currentMove].shortName;
                        move = round.moves[currentMove].duration;
                        stepTotalTime = getStepTotalTime(round.moves);
                        medias = comboMove[statusRound].medias;

                        if(!round.name.includes("Rest-Round")) {
                            currentCombo += state.isSimple ? 0 : 1;
                            currentUIMove += state.isSimple ? 0 : statusRound.split("-").length;
                        }
                        state.roundStart = false;
                        state.roundEndWarn = true;
                        state.roundEnd = true;


                        let isFound = false;
                        moveMultiplier = 0;
                        for (let index = currentMove; index < state.round?.moves?.length - 1; index++) {
                            if (statusRound === state.round?.moves[index].shortName && !isFound) {
                                moveMultiplier++;
                            } else {
                                isFound = true;
                                break;
                            }
                        }
                        let isChanged = false;
                        if (currentMove < round?.moves?.length - 1) {
                            for (let index = currentMove; index < round?.moves?.length; index++) {
                                if (statusRound !== round?.moves[index].shortName && !round?.moves[index].isAlert) {
                                    isChanged = true;
                                    nextStatusRound = round?.moves[index].shortName;
                                    break;
                                }
                            }
                            if (!isChanged) {
                                nextStatusRound = state?.rounds[currentRound + 1]?.moves[1]?.shortName;
                            }
                        } else {
                            nextStatusRound = state?.rounds[currentRound + 1]?.moves[1]?.shortName;
                        }

                        currentMoveDeadline = new Date(totalTimes.getTime() + move);
                        const stepDeadline = new Date(totalTimes.getTime() + stepTotalTime);

                        const dashArray = state.radius * Math.PI * 2;

                        const calculateStepPercentage = (time / state.total) * 100;
                        const calculateTotalPercentage = (stepTotalTime / stepTotalTime) * 100;

                        return {
                            ...state,
                            stepTime: stepTotalTime,
                            currentRound,
                            medias,
                            roundsCompleted,
                            round,
                            moveMultiplier,
                            stepTotalTime,
                            activeTime,
                            totalActiveTime,
                            nextStatusRound,
                            currentMoveDeadline,
                            roundsSpent,
                            currentCombo,
                            currentUIMove,
                            statusRound,
                            currentMove,
                            move,
                            stepDeadline,
                            innerDashOffset: dashArray - dashArray * calculateTotalPercentage / 100,
                            dashOffset: dashArray - dashArray * calculateStepPercentage / 100,
                        };
                    }

                    let { currentMove, statusRound, medias, nextMedia, nextStatusRound, currentRound, comboMove, roundStart } = state;
                    let haveMedia = false;

                    if (state.round.name.includes("Prepare-Round")) {
                        if (state.prepareStart) {
                            statusRound = state.round?.moves[currentMove]?.shortName;
                            medias = comboMove[statusRound]?.medias;
                            state.prepareStart = false;
                            haveMedia = true;
                        } else if (step < 200 && roundStart) {
                            statusRound = state.round?.moves[currentMove]?.shortName;
                            medias = comboMove["Round Start"]?.medias;
                            roundStart = false;
                            haveMedia = true;
                        } else if (step < 5000 && state.roundEndWarn) {
                            statusRound = state.round?.moves[currentMove]?.shortName;
                            medias = comboMove["Prep End Warn"]?.medias;
                            state.roundEndWarn = false;
                            haveMedia = true;
                        }
                    } else if (state.round.name.includes("Rest-Round")) {
                        if (step < 200 && roundStart) {
                            statusRound = state.round?.moves[currentMove]?.shortName;
                            medias = comboMove["Round Start"]?.medias;
                            roundStart = false;
                            haveMedia = true;
                        } else if (step < 5000 && state.roundEndWarn) {
                            statusRound = state.round?.moves[currentMove]?.shortName;
                            medias = comboMove["Rest End Warn"]?.medias;
                            state.roundEndWarn = false;
                            haveMedia = true;
                        }
                    } else {
                        if (step < 5000 && state.roundEndWarn) {
                            statusRound = state.round?.moves[currentMove]?.shortName;
                            medias = comboMove["Round End Warn"]?.medias;
                            state.roundEndWarn = false;
                            haveMedia = true;
                        } else if (step < 1500 && state.roundEnd) {
                            statusRound = state.round?.moves[currentMove]?.shortName;
                            medias = comboMove["Round End"]?.medias;
                            state.roundEnd = false;
                        } else if (step < 200 && roundStart && currentRound + 1  < state.rounds.length) {
                            statusRound = state.round?.moves[currentMove]?.shortName;
                            medias = comboMove["Rest"]?.medias;
                            roundStart = false;
                            haveMedia = true;
                        }
                    }

                    if (nextMedia) {
                        medias = nextMedia;
                        nextMedia = false;
                    }

                    let { moveMultiplier, currentCombo, currentUIMove } = state;
                    if (move && move < 0) {
                        currentMove += 1;
                        move = state.round?.moves[currentMove]?.duration;
                        currentMoveDeadline = new Date(totalTimes.getTime() + move);
                        statusRound = state.round?.moves[currentMove]?.shortName;
                        if(!state.round.name.includes("Rest-Round")) {
                            currentCombo += state.isSimple ? 0 : 1;
                            currentUIMove += state.isSimple ? 0 : statusRound?.split("-").length;
                            if (haveMedia) nextMedia = comboMove[statusRound]?.medias;
                            else medias = comboMove[statusRound]?.medias;
                        }


                        let isFound = false;
                        moveMultiplier = 0;
                        for (let index = currentMove; index < state.round?.moves?.length - 1; index++) {
                            if (statusRound === state.round?.moves[index].shortName && !isFound) {
                                moveMultiplier++;
                            } else {
                                isFound = true;
                                break;
                            }
                        }
                        let isChanged = false;
                        if (currentMove < state.round?.moves?.length - 1) {
                            for (let index = currentMove; index < state.round?.moves?.length; index++) {
                                if (statusRound !== state.round?.moves[index].shortName && !state.round?.moves[index].isAlert) {
                                    isChanged = true;
                                    nextStatusRound = state.round?.moves[index].shortName;
                                    break;
                                }
                            }
                            if (!isChanged) {
                                nextStatusRound = state?.rounds[currentRound + 1]?.moves[1]?.shortName;
                            }
                        } else {
                            nextStatusRound = state?.rounds[currentRound + 1]?.moves[1]?.shortName;
                        }
                    }

                    const dashArray = state.radius * Math.PI * 2;
                    const dashInnerArray = state.innerRadius * Math.PI * 2;

                    const calculateStepPercentage = (step / state.stepTotalTime) * 100;
                    const calculateTotalPercentage = (time / state.total) * 100;

                    return {
                        ...state,
                        stepTime: step,
                        totalTime: time,
                        currentMove,
                        move,
                        medias,
                        statusRound,
                        moveMultiplier,
                        nextMedia,
                        currentCombo,
                        roundStart,
                        currentUIMove,
                        nextStatusRound,
                        currentMoveDeadline,
                        innerDashOffset: dashInnerArray - dashInnerArray * calculateStepPercentage / 100,
                        dashOffset: dashArray - dashArray * calculateTotalPercentage / 100,
                        dashArray: state.radius * Math.PI * 2,
                        innerDashArray: dashInnerArray
                    };
                }
            }

            return state;
        }

        case CURRENT_TIME: {
            return state;
        }

        case STOP_TIME: {
            let { totalActiveTime, activeTime, currentCombo, stepTotalTime, stepDeadline } = state;
            const now = new Date();
            let step = stepDeadline.getTime() - now.getTime();
            step = stepTotalTime - step;
            totalActiveTime = totalActiveTime ? totalActiveTime + step : step;
            activeTime = activeTime ? activeTime + step : step;

            return {
                ...state,
                isfocued: false,
                isStopped: true,
                isForcedStoped: !state.round.name.includes("Prepare-Round"),
                lockedActiveTime: activeTime,
                lockedTotalActiveTime: totalActiveTime,
                lockedCombos: currentCombo
            };
        }

        case NEXT_STEP: {
            const { rounds } = state;

            let {
                currentMove,
                currentMoveDeadline,
                currentRound,
                stepTotalTime,
                statusRound,
                roundsSpent,
                activeTime,
                totalActiveTime,
                moveMultiplier,
                stepDeadline,
                roundsCompleted,
                currentUIMove,
                medias,
                comboMove,
                move,
                nextStatusRound
            } = state;

            if (currentRound  <= rounds.length) {
                const totalTimes = new Date();
                let step = stepDeadline.getTime() - totalTimes.getTime();
                step = stepTotalTime - step;
                const spentTimeInPercent = (step / stepTotalTime) * 100;

                totalActiveTime = (totalActiveTime && spentTimeInPercent >= 5) ? totalActiveTime + step : step;
                if (!state.round.name.includes("Prepare-Round") && !state.round.name.includes("Rest-Round")) {
                    roundsSpent += 1;
                    activeTime = (activeTime && spentTimeInPercent >= 5) ? activeTime + step : step;
                    roundsCompleted = spentTimeInPercent >= 5 ? roundsCompleted + 1 : roundsCompleted;
                }

                const totalTime = state.totalTime - state.stepTime;

                currentRound += 1;
                currentMove = 0;
                stepTotalTime = getStepTotalTime(rounds[currentRound].moves);

                const round = rounds[currentRound];
                move = (!state.round.name.includes("Prepare-Round") && !state.round.name.includes("Rest-Round")) ? round.moves[currentMove+1].duration : round.moves[currentMove].duration;
                medias = (!state.round.name.includes("Prepare-Round") && !state.round.name.includes("Rest-Round"))
                    ? comboMove[round?.moves[currentMove+1].shortName].medias : comboMove[round?.moves[currentMove].shortName].medias;

                state.roundEndWarn = true;
                state.roundEnd = true;
                statusRound = round?.moves[currentMove+1].shortName;


                let isFound = false;
                moveMultiplier = 0;
                for (let index = currentMove; index < state.round?.moves?.length - 1; index++) {
                    if (statusRound === state.round?.moves[index].shortName && !isFound) {
                        moveMultiplier++;
                    } else {
                        isFound = true;
                        break;
                    }
                }
                let isChanged = false;
                if (currentMove < round?.moves?.length - 1) {
                    for (let index = currentMove; index < round?.moves?.length; index++) {
                        if (statusRound !== round?.moves[index].shortName && !round?.moves[index].isAlert) {
                            isChanged = true;
                            nextStatusRound = round?.moves[index].shortName;
                            break;
                        }
                    }
                    if (!isChanged) {
                        nextStatusRound = rounds[currentRound + 1]?.moves[1]?.shortName;
                    }
                } else {
                    nextStatusRound = rounds[currentRound + 1]?.moves[1]?.shortName;
                }

                const totalDeadline = new Date(totalTimes.getTime() + totalTime);
                stepDeadline = new Date(totalTimes.getTime() + stepTotalTime);
                currentMoveDeadline = new Date(totalTimes.getTime() + move);

                const dashArray = state.radius * Math.PI * 2;

                const calculateStepPercentage = (totalTime / state.total) * 100;
                const calculateTotalPercentage = (stepTotalTime / stepTotalTime) * 100;

                return {
                    ...state,
                    stepTime: stepTotalTime,
                    currentRound,
                    medias,
                    round,
                    totalActiveTime,
                    activeTime,
                    totalTime,
                    stepTotalTime,
                    roundsCompleted,
                    roundsSpent,
                    currentUIMove,
                    moveMultiplier,
                    nextStatusRound,
                    currentMoveDeadline,
                    totalDeadline,
                    statusRound,
                    currentMove,
                    move,
                    stepDeadline,
                    innerDashOffset: dashArray - dashArray * calculateTotalPercentage / 100,
                    dashOffset: dashArray - dashArray * calculateStepPercentage / 100,
                };
            }

            return state;
        }
        default:
            return state;
    }
};

export const timerResponse = (state = "TIMER_RESPONSE_INIT", action) => {
    switch (action.type) {
        case TIMER_RESPONSE_SUCCESS:
            return TIMER_RESPONSE_SUCCESS;
        case TIMER_RESPONSE_WORKING:
            return TIMER_RESPONSE_WORKING;
        default:
            return state;
    }
};

const getStepTotalTime = moves => moves?.length
    ? moves.reduce((total, move) => move.isAlert ? total : move.duration + total, 0)
    : 0 ;
const getTotalTime = rounds => rounds?.length
    ? rounds.reduce((total, round) => getStepTotalTime(round.moves) + total, 0)
    : 0 ;

const getTotalCombos = rounds => rounds?.length
    ? rounds.reduce((total, round) => (round.moves?.length && !round?.prepare && !round?.rest)
        ? total + round.moves.reduce((total, move) => (move.shortName.includes("Prepare") || move.shortName.includes("Rest") || move.isAlert) ? total : total + 1, 0)
        : total , 0)
    : 0 ;
const getTotalMoves = rounds => rounds?.length
    ? rounds.reduce((total, round) => (round.moves?.length && !round?.prepare && !round?.rest)
        ? total + round.moves.reduce((total, move) => (move.shortName.includes("Prepare") || move.shortName.includes("Rest") || move.isAlert) ? total : total + move.shortName?.split("-").length, 0)
        : total , 0)
    : 0 ;
