import "./style.scss";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { initConfigInputs, setComboGenrateStatus } from "Redux/actionCreators";
import ConfigureFormTabs from "Components/ConfigureFormTabs";
import ConfigureFormInputs from "Components/ConfigureFormInputs";
import SharingCards from "Components/SharingCards";
import { useHistory } from "react-router";
import { bookMarkModal } from "../../Redux/actionCreators";

const ConfigureForm = ({ initConfigInputs, setComboGenrateStatus, comboGenrateResponse, bookMarkModal }) => {
    const [ shareCard, setShareCard ] = useState(false);
    const history = useHistory();
    useEffect(() => {
        if(comboGenrateResponse){
            history.push(`/?workoutid=${comboGenrateResponse}`);
            setComboGenrateStatus(null);
        }
    },[comboGenrateResponse]);
    return (
        <section styleName="configure-section">
            <div className="container">
                <ConfigureFormTabs />
                <ConfigureFormInputs />
                <button onClick={() =>  bookMarkModal(true)} className="btn-default">Save Configuration</button>
                <button onClick={() => initConfigInputs()} className="btn-primary">Restore Default</button>
            </div>
            { shareCard && <SharingCards onClose={() => setShareCard(false)} bookMark={shareCard} /> }
        </section>
    );
};

const mapStateToProp = state => {
    return {
        comboGenrateResponse: state.comboGenrateResponse,
        bookMarkModalReducer: state.bookMarkModalReducer,
    };
};

const mapDispatchToProps = dispatch => ({
    initConfigInputs: () => dispatch(initConfigInputs()),
    bookMarkModal: (toggle) => dispatch(bookMarkModal(toggle)),
    setComboGenrateStatus: payload => dispatch(setComboGenrateStatus(payload))
});

export default connect(mapStateToProp, mapDispatchToProps)(ConfigureForm);
