import "./style.scss";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import CircularProgressBar from "Components/CircularProgressBar";
import { useWindowSize } from "Common/Hooks/useWindowSize";
import { useAudio } from "Common/Hooks/useAudio";

const BoxingTimers = ({
    currentTime,
    startTimer,
    timerResponse,
    playAudioState
}) => {
    const [ totalTime ] = useState({
        minutes: 18,
        seconds: 0
    });
    const [media, setMedia] = useState(false);
    const [move, setMove] = useState(false);

    const size = useWindowSize();
    const [, toggle, setUrl] = useAudio();

    const { innerHeight: height } = size;
    const VeryLongScreen = innerHeight > 670;
    const isLongScreen = innerHeight > 600;
    const isSmall = innerHeight < 530;

    useEffect(() => {
        const { isStopped } = currentTime;
        return () => !isStopped && startTimer();
    }, []);

    useEffect(() => {
        if (timerResponse === "TIMER_RESPONSE_SUCCESS") {
            setMove(false);
            setMedia(null);
            setUrl(null);
            toggle(false);
        }
    }, [timerResponse]);

    useEffect(() => {
        const { isStopped } = currentTime;
        if (playAudioState && !isStopped) {
            const { medias, currentMove } = currentTime;

            if (medias && (medias.url !== media || currentMove !== move)) {
                setMove(currentMove);
                setMedia(medias.url);
                setUrl(medias.url);
                toggle(true);
            }
        } else {
            setUrl(null);
            toggle(false);
        }
    }, [currentTime, playAudioState]);

    useEffect(() => {
        toggle(playAudioState);
    }, [playAudioState]);

    return (
        <section /* style={{ height: size.isMobile ? `${innerHeight - 240}px` : "auto" }} */ styleName={classNames("boxing-timers-section", {
            "boxing-timers-section--long-screen": !VeryLongScreen && size.isMobile,
            "boxing-timers-section--desktop": !size.isMobile
        })}>
            <div styleName={classNames("boxing-timers", {
                "boxing-timers--long-screen": !VeryLongScreen && size.isMobile,
                "boxing-timers--desktop": !size.isMobile
            })}>
                <CircularProgressBar
                    sqSize={!size.isMobile ? 562 : VeryLongScreen ? 310 : isLongScreen ? 260 : isSmall ? 200 : 300}
                    strokeWidth={size.isMobile ? 10 : 20}
                    setTotalTime={totalTime} />
            </div>
        </section>
    );
};

const mapStateToProp = state => {
    return {
        currentTime: state.currentTime,
        playAudioState: state.playAudio,
        timerResponse: state.timerResponse
    };
};

const mapDispatchToProps = dispatch => {
    return {
        stopTimer: () => dispatch(stopTimer())
    };
};

export default connect(mapStateToProp, mapDispatchToProps)(BoxingTimers);
