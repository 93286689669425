import PropTypes from "prop-types";
import "./style.scss";

const SearchForm = ({ name, value, onChange }) => {

    const onchange = event => {
        const { value, name } = event.target;

        if (onChange) {
            onChange(value, name);
        }
    };

    return (
        <label styleName="search-wrap">
            <span styleName="search-wrap__icon">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#2974A3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M21.35 21.35L17 17" stroke="#2974A3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>
            <input
                name={name}
                styleName="search-wrap__input"
                value={value}
                onChange={onchange}
                placeholder="Search"
            />
        </label>
    );
};

SearchForm.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

SearchForm.defaultProps = {
    value: "",
    onChange: () => {},
    onBlur: () => {}
};

export default SearchForm;
