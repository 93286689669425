import { SET_ACTIVE_TAB, CONFIG_TAB } from "../actionTypes";
import { tabs } from "Common/configInputs";

const initialState = tabs;

export const configWorkout = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_TAB: {
            const tempState = state.map(tab => {
                tab.isActive = tab.key === action.payload;
                return tab;
            });
            return tempState;
        }
        case CONFIG_TAB: {
            return state;
        }
        default:
            return state;
    }
};
