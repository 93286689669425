import * as actions from "../actionTypes";
import { post, get } from "APIs/resource";

export const mainMiddleware = ({ getState, dispatch }) => next => async action => {
    next(action);

    switch (action.type) {
        case actions.START_TIME: {
            dispatch({
                type: actions.RUNNING_TIME,
                payload: null
            });

            return action;
        }
        case actions.INIT_CONFIG_INPUTS: {
            let defaults;
            if(action?.payload){
                defaults = await get(`boxing/combo-generate/request/${action.payload}`);
            } else {
                defaults = await get("/admin/bm-config/default");
            }
            if (defaults.status === 200) {
                const { data } = defaults;
                const vals = action?.payload ? data.request : data.defaults;
                const ratio = action?.payload ? data.request.moveratio : data?.defaults.movesRatio;
                const preparTime = action?.payload ? data.request.prepTime : data?.defaults.prepareTime;

                dispatch({
                    type: actions.CONFIG_INPUTS_UPDATE,
                    payload: {
                        activeFields: {
                            ...vals,
                            unique: "No",
                            moves: ratio,
                            prepareTime: preparTime
                        }
                    }
                });

                dispatch({
                    type: actions.SUBMIT_CONFIG,
                    payload: null
                });
            }
            return action;
        }
        case actions.NEXT_STEP: {
            dispatch({
                type: actions.RUNNING_TIME,
                payload: null
            });

            return action;
        }
        case actions.STOP_TIME: {
            dispatch({
                type: actions.SUBMIT_CONFIG,
                payload: null
            });
            return action;
        }
        case actions.SAVE_CONFIG_INPUTS: {
            const { configWorkout, configInputs } = getState();
            const activeTab = configWorkout.find(config => config.isActive);
            const payload = configInputs[activeTab.key];
            const  { activeFields } = payload;
            const request = {
                ...activeFields,
                cooldownTime: activeFields.cooldownTime || 0,
                unique: activeFields.unique === "Yes",
                prepTime: activeFields.prepareTime,
                stimer: activeTab.key === "simple-timer"
            };
            const response = await post("/boxing/combo-generate", request);
            // const payload = {
            //     roundStartTM: null,
            //     comboMove: null,
            //     request: {
            //         modifiedOn: null,
            //         skillLevel: "Intermediate",
            //         id: null,
            //         stimer: false,
            //         moves: ["Footwork", "Punch", "Defense"],
            //         rounds: 5,
            //         mvratval: {"Footwork": 25, "Punch": 100, "Defense": 15},
            //         wf: {"Footwork": 17.86, "Punch": 71.43, "Defense": 10.71},
            //         status: null,
            //         roundTime: 180000,
            //         stance: "Orthodox",
            //         wpr: true,
            //         unique: false,
            //         createdOn: null,
            //         restTime: 30000,
            //         repeats: "Some"
            //     },
            //     restEndWarnTM: null,
            //     id: 6321436014673920,
            //     prepEndWarnTM: null,
            //     roundEndWarnTM: null,
            //     roundEndTM: null,
            //     rounds: null
            // };
            if (response.status === 200) {
                const { data } = response;
                const { id } = data;
                dispatch({
                    type: actions.COMBO_GENRATE_SUCCESS,
                    payload: id
                });

                dispatch({
                    type: actions.SHARE_TOGGLE,
                    payload: true
                });
            }
            return action;
        }
        case actions.GET_BOXING_PRIMER_MOVE: {
            let { currentTime } = getState();
            const payloadMove = action.payload;

            if (!currentTime.rounds?.length) {
                const { configWorkout, configInputs } = getState();
                const activeTab = configWorkout.find(tab => tab.isActive);
                const payload = configInputs[activeTab.key];

                const { data } = await configGenerate(payload, actions, dispatch);
                currentTime = data;
            }
            const { rounds, comboMove } = currentTime;

            let move = rounds.find(round => round.moves.find(move => comboMove[move?.shortName]?.longName === payloadMove));
            move = move && move.moves.find(mov => comboMove[mov?.shortName]?.longName === payloadMove);
            move = move ? {...move, ...comboMove[move.shortName]} : { longName: payloadMove, shortName: payloadMove };

            dispatch({
                type: actions.GET_CURRENT_PRIMER_MOVE,
                payload: move
            });

            return action;
        }
        case actions.SUBMIT_CONFIG: {
            const { configWorkout, configInputs } = getState();
            const activeTab = configWorkout.find(tab => tab.isActive);
            const payload = configInputs[activeTab.key];
            const  { activeFields } = payload;

            const request = {
                ...activeFields,
                cooldownTime: activeFields.cooldownTime || 0,
                unique: activeFields.unique === "Yes",
                prepTime: activeFields.prepareTime,
                stimer: activeTab.key === "simple-timer"
            };

            const response = await post("/boxing/combo-generate", request);

            if (response.status === 200) {
                const { data } = response;
                const { rounds, request, comboMove } = data;

                dispatch({
                    type: actions.UPDATE_TIME,
                    payload: { rounds, request, comboMove }
                });

                dispatch({
                    type: actions.TIMER_RESPONSE_SUCCESS,
                    payload: null
                });
            }
            setTimeout(() => {
                dispatch({
                    type: actions.TIMER_RESPONSE_WORKING,
                    payload: null
                });
            }, 2000);

            return action;
        }
        case actions.UPDATE_TIME: {
            dispatch({
                type: actions.PREVIEW_COMBOS,
                payload: null
            });

            return action;
        }
        case actions.PREVIEW_COMBOS: {
            const { currentTime } = getState();
            const { rounds } = currentTime;

            if (rounds.length < 1) {
                dispatch({
                    type: actions.INIT_TIME,
                    payload: null
                });
            }

            return action;
        }
        case actions.GET_BOXING_PRIMER: {
            const response = await get("/admin/boxing-moves");

            if (response.status === 200) {
                const { data } = response;

                dispatch({
                    type: actions.GET_BOXING_PRIMER_SUCCESS,
                    payload: data
                });
            }

            return action;
        }
        case actions.ABOUT_TEXT: {
            dispatch({
                type: actions.ABOUT_LOADING,
                payload: null
            });

            const response = await get("help_center/articles/4403291175193.json", {}, true);

            if (response.status === 200) {
                const { data } = response;

                dispatch({
                    type: actions.ABOUT_SUCCESS,
                    payload: data.article
                });
            }
            return action;
        }
    }

};

const configGenerate = async (payload, actions, dispatch) => {
    const  { activeFields } = payload;

    const request = {
        ...activeFields,
        cooldownTime: activeFields.cooldownTime || 0,
        unique: activeFields.unique === "Yes",
        prepTime: activeFields.prepareTime
    };

    const response = await post("/boxing/combo-generate", request);

    if (response.status === 200) {
        const { data } = response;
        const { rounds, request, comboMove } = data;

        dispatch({
            type: actions.UPDATE_TIME,
            payload: { rounds, request, comboMove }
        });

        dispatch({
            type: actions.TIMER_RESPONSE_SUCCESS,
            payload: null
        });
    }
    setTimeout(() => {
        dispatch({
            type: actions.TIMER_RESPONSE_WORKING,
            payload: null
        });
    }, 2000);

    return response;
};
