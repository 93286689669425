export const config = {
    prepareTime: {
        status: "prepare",
        minutes: 1,
        seconds: 0
    },
    roundsTime: [
        {
            status: "work",
            minutes: 5,
            seconds: 0
        },
        {
            status: "work",
            minutes: 5,
            seconds: 0
        },
        {
            status: "work",
            minutes: 5,
            seconds: 0
        }
    ],
    restTime: {
        status: "rest",
        minutes: 1,
        seconds: 0
    }
};

export const getTime = time => {
    const milliSeconds = Math.floor(time % 1000);
    const seconds = Math.floor((time /  1000)) % 60;
    const minutes = Math.floor((time / 60000)) % 60;

    return {minutes, seconds, milliSeconds};
};

export const totalSeconds = time => (time?.minutes || 0) * 60 + (time?.seconds || 0);
