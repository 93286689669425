import * as actions from "./actionTypes";

export const toggleNavigation = data => ({
    type: actions.TOGGLE_NAVIGATION,
    payload: data
});

export const startTimer = () => ({
    type: actions.START_TIME,
    payload: null
});

export const stopTimer = () => ({
    type: actions.STOP_TIME,
    payload: null
});

export const workoutEnded = () => ({
    type: actions.WORK_OUT_ENDED,
    payload: null
});

export const initTimer = data => ({
    type: actions.INIT_TIME,
    payload: data
});


export const currentTimer = () => ({
    type: actions.CURRENT_TIME,
    payload: null
});

export const runningTimer = () => ({
    type: actions.RUNNING_TIME,
    payload: null
});

export const updateSize = data => ({
    type: actions.UPDATE_SIZE,
    payload: data
});

export const nextStep = () => ({
    type: actions.NEXT_STEP,
    payload: null
});

export const setActiveTab = data => ({
    type: actions.SET_ACTIVE_TAB,
    payload: data
});

export const updateInputs = data => ({
    type: actions.CONFIG_INPUTS_UPDATE,
    payload: data
});

export const initConfigInputs = data => ({
    type: actions.INIT_CONFIG_INPUTS,
    payload: data
});


export const bookMarkModal = data => ({
    type: actions.BOOK_MARK_MODAL,
    payload: data
});

export const shareToggle = data => ({
    type: actions.SHARE_TOGGLE,
    payload: data
});

export const setComboGenrateStatus = payload => ({
    type: actions.COMBO_GENRATE_SUCCESS,
    payload
});

export const saveConfigInputs = () => ({
    type: actions.SAVE_CONFIG_INPUTS,
    payload: null
});

export const configInputs = () => ({
    type: actions.CONFIG_INPUTS,
    payload: null
});

export const submitConfig = () => ({
    type: actions.SUBMIT_CONFIG,
    payload: null
});

export const resetInputs = data => ({
    type: actions.RESET_INPUTS,
    payload: data
});

export const previewCombos = () => ({
    type: actions.PREVIEW_COMBOS,
    payload: null
});

export const getBoxingPrimer = data => ({
    type: actions.GET_BOXING_PRIMER,
    payload: data,
});

export const getBoxingPrimerMove = data => ({
    type: actions.GET_BOXING_PRIMER_MOVE,
    payload: data,
});

export const selectSearchSuggestion = data => ({
    type: actions.SELECT_SEARCH_SUGGESTION,
    payload: data,
});

export const playAudio = data => ({
    type: actions.PLAY_AUDIO,
    payload: data
});

export const aboutModal = data => ({
    type: actions.ABOUT_MODAL,
    payload: data
});

export const aboutText = () => ({
    type: actions.ABOUT_TEXT,
    payload: null
});

export const currentTheme = theme => ({
    type: actions.CURRENT_THEME,
    payload: theme
});
