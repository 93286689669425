import reduce from "lodash/reduce";
import brandRules from "Common/metaTags";

const deleteNulls = (obj) => {
    Object.keys(obj).forEach((k) => {
        if (!obj[k] || obj[k] === null) {
            delete obj[k];
        }
    });
};

const getMetaTags = (pathname) => {
    if (!pathname) return [];
    const acceptedRules = brandRules.filter(({test}) =>
        pathname && typeof pathname === "string" ? pathname.match(test) : false
    );
    const tagsWithNormalizedKeys = reduce(acceptedRules,(result, rule) => {
        return Object.assign(result, ejectCombinedKeys(rule.tags));
    }, {});
    deleteNulls(tagsWithNormalizedKeys);
    return evaluatedTags(tagsWithNormalizedKeys, {pathname});
};

const getTemplate = (key) => {
    const Social = ({name, value}) => <meta key={name} property={name} content={value} />;
    const templates = {
        "title": ({value}) => <title>{process.env.ENVIRONMENT.includes("prod") ? value : `${value} - ${COMMIT_SHA}`}</title>,
        "description": ({value}) => <meta name="description" content={value} />,
        "canonical": ({value}) => <link rel="canonical" href={value} />,
        "noIndex": () => <meta name="robots" content="noindex, nofollow" />,
        "altLanguages": ({value}) => value.map(({lang, href}) => <link rel="alternate" key={lang} hrefLang={lang} href={href} />),
    };
    return templates[key] || Social;
};

const ejectCombinedKeys = (tagsWithCombinedKeys) => {
    return reduce(tagsWithCombinedKeys,(result, value, key) => {
        key.split("|").forEach(key => {
            result[key] = value;
        });
        return result;
    }, {});
};

const evaluatedTags = (tags, dynamicParams) => {
    return reduce(tags,(result, value, key) => {
        result[key] = typeof value === "function" ? value(dynamicParams) : value;
        return result;
    }, {});
};

export default {
    getMetaTags,
    getTemplate,
    evaluatedTags,
    ejectCombinedKeys,
};
