import { COMBO_GENRATE_SUCCESS } from "../actionTypes";

export const comboGenrateResponse = (state = false, action) => {
    switch (action.type) {
        case COMBO_GENRATE_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};
