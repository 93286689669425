import "./style.scss";
import BoxingWorkout  from "Components/BoxingWorkout";
import BoxingTimers  from "Components/BoxingTimers";
import ActionButtons  from "Components/ActionButtons";
import CombosAndMoves from "Components/CombosAndMoves";
import { useWindowSize } from "Common/Hooks/useWindowSize";

const Home = () => {
    const { isMobile, height } = useWindowSize();

    return isMobile ? (
        <div style={{height: `${height - 59}px`}} styleName="mobile-wrapper">
            <BoxingWorkout />
            <BoxingTimers />
            <ActionButtons />
        </div>
    ): (
        <div className="container">
            <div styleName="wrapper">
                <div styleName="wrapper__col">
                    <BoxingWorkout />
                    <CombosAndMoves />
                    <ActionButtons />
                </div>
                <BoxingTimers />
            </div>
        </div>
    );
};

export default Home;
