export const images = [
    "sharing/photo-1549719386-74dfcbf7dbed.jpg",
    "sharing/photo-1550314335-4d4681b9d66a.jpg",
    "sharing/photo-1555661530-68c8e98db4e6.jpg",
    "sharing/photo-1557682224-5b8590cd9ec5.jpg",
    "sharing/photo-1557682250-33bd709cbe85.jpg",
    "sharing/photo-1557682260-96773eb01377.jpg",
    "sharing/photo-1557683304-673a23048d34.jpg",
    "sharing/photo-1557683311-eac922347aa1.jpg",
    "sharing/photo-1478760329108-5c3ed9d495a0.jpg",
    "sharing/photo-1509255929945-586a420363cf.jpg",
    "sharing/photo-1521800641212-77d98bb90d21.jpg",
    "sharing/photo-1522844931788-513a2ed46b4b.jpg",
    "sharing/photo-1579546929662-711aa81148cf.jpg",
    "sharing/photo-1579547945413-497e1b99dac0.jpg",
    "sharing/photo-1583473848882-f9a5bc7fd2ee.jpg",
    "sharing/photo-1589456506629-b2ea1a8576fb.jpg",
    "sharing/photo-1591117207239-788bf8de6c3b.jpg",
    "sharing/photo-1593352216894-89108a0d2653.jpg",
    "sharing/photo-1597079997475-c2a3a51df761.jpg",
    "sharing/photo-1604342427523-189b17048839.jpg",
    "sharing/photo-1606211043650-3a4edc7e4e2d.jpg",
    "sharing/photo-1610543123775-b92397d8ef58.jpg",
    "sharing/photo-1616652836576-c4115253b2d7.jpg"
];

export const bookMarkImages = [
    "sharing/cgo_config_share_image_combos_large.jpg"
];
