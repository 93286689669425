import "./style.scss";
import Combos from "Components/Combos";
import Loader from "Components/Loader";
import { useEffect } from "react";
import { connect } from "react-redux";
import { previewCombos, initConfigInputs } from "Redux/actionCreators";

const PreviewCombos = ({
    initConfigInputs,
    previewCombosDispath,
    currentTimeState,
    timerResponse
}) => {
    const previewCombos = currentTimeState.rounds;
    const previewCombosMove = currentTimeState.comboMove;
    useEffect(() => {
        if (timerResponse === "TIMER_RESPONSE_INIT") initConfigInputs();
        previewCombosDispath();
    }, []);

    return (
        (previewCombos && previewCombos?.length) ? (
            <div className="container">
                {
                    previewCombos.map(previewCombo => (
                        <div key={previewCombo.name} styleName="preview-combo">
                            <>
                                {
                                    (!previewCombo.rest && !previewCombo.prepare) && (
                                        <h3 styleName="round-name">{previewCombo.name}</h3>
                                    )
                                }
                                {
                                    (previewCombo.moves && previewCombo.moves?.length) && <Combos comboMove={previewCombosMove} combos={previewCombo.moves} />
                                }
                            </>
                        </div>
                    ))
                }
                <span styleName="end-workout">End of Workout</span>
            </div>
        ): <Loader />
    );
};

const mapStateToProp = state => {
    return {
        currentTimeState: state.currentTime,
        timerResponse: state.timerResponse
    };
};

const mapDispatchToProps = dispatch => ({
    previewCombosDispath: () => dispatch(previewCombos()),
    initConfigInputs: () => dispatch(initConfigInputs())
});

export default connect(mapStateToProp, mapDispatchToProps)(PreviewCombos);
