import "./style.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import { setActiveTab, submitConfig } from "Redux/actionCreators";

const ConfigureFormTabs = ({
    configTabsState,
    submitConfig,
    setActiveTab
}) => (
    <div styleName="tabs">
        {
            configTabsState && configTabsState.map(tab => (
                <span onClick={() => {
                    setActiveTab(tab.key);
                    submitConfig();
                }} key={tab.key} styleName={classNames("tab", {
                    "tab--active": tab.isActive
                })}>{tab.title}</span>
            ))
        }
    </div>
);

const mapStateToProp = state => ({
    configTabsState: state.configWorkout,
});

const mapDispatchToProps = dispatch => ({
    setActiveTab: data => dispatch(setActiveTab(data)),
    submitConfig: () => dispatch(submitConfig())
});

export default connect(mapStateToProp, mapDispatchToProps)(ConfigureFormTabs);
