import Header from "./Header";
import Footer from "./Footer";
import Alert from "Components/Alert";
import MobileNavigation from "Components/MobileNavigation";
import OnBoardingModal from "Components/OnBoardingModal";
import SharingCards from "Components/SharingCards";
import { getPageInfo } from "Common/pageHelpers";
import { connect } from "react-redux";
import { aboutModal } from "Redux/actionCreators";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import BookMarkModal from "Components/BookMarkModal";
import { bookMarkModal } from "../Redux/actionCreators";

const Layout = ({
    children,
    location,
    currentTime,
    aboutModal,
    aboutModalState,
    theme,
    bookMarkModal,
    bookMarkModalTogal,
}) => {
    const [sharingCards, setSharingCards] = useState(false);
    const [bookMark, setBookMark] = useState(false);
    const { title, isHeader, isFooter, backPath } = getPageInfo();
    const cookies = new Cookies();
    const { isCompletedWorkout, isForcedStoped } = currentTime;

    useEffect(() => {
        aboutModal(!cookies.get("onboarded"));
    }, []);

    useEffect(() => {
        document.querySelector("body").className = theme;
    }, [theme]);

    useEffect(() => {
        if (!sharingCards) {
            setSharingCards(isCompletedWorkout || isForcedStoped);
        }
    }, [isCompletedWorkout, isForcedStoped]);

    const onclose = reminder => {
        if (!reminder) {
            let date = new Date();
            date = date.setDate(date.getDate() + 7);
            cookies.set("onboarded", true, { path: "/", expires: new Date(date) });
        } else cookies.set("onboarded", true, { path: "/" });
        aboutModal(!cookies.get("onboarded"));
    };

    return (
        <div className={theme}>
            <Header
                location={location}
                title={title}
                backPath={backPath}
                isHeader={isHeader}
            />

            <div className="content">
                {/* <Alert /> */}
                {children}
            </div>
            {
                isFooter && <Footer />
            }
            <MobileNavigation />

            {
                aboutModalState && <OnBoardingModal
                    open={aboutModalState}
                    onClose={onclose}
                />
            }
            {bookMarkModal && <BookMarkModal open={bookMarkModal} onClose={ () => bookMarkModalTogal(false)} bookMark={setBookMark}  onShare ={setSharingCards}/> }
            {sharingCards && <SharingCards onClose={() => {
                setBookMark(false);
                setSharingCards(false);
            }} bookMark={ bookMark } />}
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    aboutModal: toggle => dispatch(aboutModal(toggle)),
    bookMarkModalTogal: (toggle) => dispatch(bookMarkModal(toggle))
});

const mapStateToProps = state => ({
    aboutModalState: state.aboutModal,
    currentTime: state.currentTime,
    theme: state.theme,
    bookMarkModal: state.bookMarkModalReducer
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
