import { useState, useEffect } from "react";
import { Howl } from "howler";
import { getAsset } from "Common/url";

export const useAudio = () => {
    const [audio, setAudio] = useState(false);
    const [playing, setPlaying] = useState(false);

    const toggle = data => setPlaying(data);

    useEffect(() => {
        if (audio) {
            (playing) ? audio.play() : audio.pause();
        }
    }, [playing, audio]);

    // useEffect(() => {
    //     return () => {
    //         if (audio) {
    //             audio.unload();
    //             setPlaying(false);
    //         }
    //     };
    // }, [audio]);

    const setUrl = url => {
        if (url) setAudio(
            new Howl({
                src: [getAsset(url)]
            }));
        else setAudio(false);
    };

    return [playing, toggle, setUrl];
};
