import { useState, useEffect } from "react";
import { useWakeLock } from "react-screen-wake-lock";

export const useScreenAwake = () => {
    const [screenAwake, setScreenAwake] = useState(false);
    const { isSupported, request, release } = useWakeLock({
        onRequest: () => {},
        onError: () => {},
        onRelease: () => {},
    });

    useEffect(() => {
        if (screenAwake) request();
        else release();
    }, [screenAwake]);

    return [screenAwake, setScreenAwake, isSupported];
};
