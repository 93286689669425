import "./style.scss";

const Footer = () => {

    return (
        <footer styleName="footer">
        </footer>
    );
};

export default Footer;
