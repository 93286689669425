import routes from "./routes";
import { useMemo } from "react";
import { useLocation } from "react-router";

const getPageInfo = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const pageInfo = useMemo(() => {
        const path = location.pathname;
        const info = path && routes.find(r => {
            const lpath = path.split("/");
            const rpath = r.path.split("/");
            if (lpath.length !== rpath.length) return false;
            for(let i = 0; i < lpath.length; ++i){
                if (lpath[i] !== rpath[i] && !rpath[i].includes(":")) {
                    return false;
                }
            }
            return true;
        });
        return info;
    }, [location]);

    return pageInfo;
};

export {
    getPageInfo,
};
