import {
    GET_BOXING_PRIMER_SUCCESS,
    SELECT_SEARCH_SUGGESTION,
    GET_CURRENT_PRIMER_MOVE
} from "../actionTypes";

export const boxingPrimer = (state = false, action) => {
    switch (action.type) {
        case GET_BOXING_PRIMER_SUCCESS: {
            const { moves } = action.payload;

            return moves;
        }
        default:
            return state;
    }
};

export const searchSuggestion = (state = [], action) => {
    switch (action.type) {
        case SELECT_SEARCH_SUGGESTION: {
            const isHAve = state.find(sat => sat === action.payload);
            if (isHAve)
                return state.filter(sat => sat !== action.payload);

            return [...state, action.payload];
        }
        default:
            return state;
    }
};

export const currentPrimerMove = (state = false, action) => {
    switch (action.type) {
        case GET_CURRENT_PRIMER_MOVE:
            return action.payload || false;
        default:
            return state;
    }
};
