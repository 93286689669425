import "./style.scss";
import PropTypes from "prop-types";
import classNames from "classnames";

const Loader = ({ notFullHeight }) => {
    return (
        <div styleName={classNames("flex-center", {
            "full-height": !notFullHeight
        })}>
            <div styleName="loader"/>
        </div>
    );
};

Loader.propTypes = {
    notFullHeight: PropTypes.bool,
};

Loader.defaultProps = {
    notFullHeight: false,
};

export default Loader;
