import uniqueId from "lodash/uniqueId";

class EventSystem {
    EVENTS_QUEUE = {};

    subscribe = (component, event) => {
        if (typeof component === "undefined") {
            return;
        }

        if (!component.id) {
            component.id = uniqueId();
        }

        this.EVENTS_QUEUE[event] = this.EVENTS_QUEUE[event] || [];

        const eventInQueue = this.EVENTS_QUEUE[event].find(queue => {
            return queue.id === component.id;
        });

        if (!eventInQueue) {
            this.EVENTS_QUEUE[event].push(component);
        }
    };

    unsubscribe = (component, event) => {
        this.EVENTS_QUEUE[event] = (this.EVENTS_QUEUE[event] || []).filter(queue => queue.id !== component.id);
    };

    publish = (event, data) => {
        (this.EVENTS_QUEUE[event] || []).forEach(queue => {
            typeof queue[`on${event}`] === "function" && queue[`on${event}`](data);
        });
    };
}

export const eventSystem = new EventSystem();
