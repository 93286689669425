import "./style.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import InputTime from "Components/InputTime";
import { alertService } from "Services/alertService";
import { updateInputs, submitConfig, initConfigInputs, currentTheme } from "Redux/actionCreators";
import { getTime, totalSeconds } from "Common/helpers";
import _ from "lodash";
import { useEffect } from "react";

const ConfigureFormInputs = ({
    configTabsState,
    configInputsState,
    initConfigInputs,
    updateInputs,
    timerResponse,
    submitConfig,
    currentTheme,
    theme
}) => {

    const currentTabTemp = configTabsState && configTabsState.find(tab => tab.isActive);
    const inputFields = configInputsState[currentTabTemp.key];

    const onChange = (value, name) => {
        const { activeFields } = inputFields;
        activeFields[name] = parseInt(value);
        updateInputs({ activeFields });
    };

    useEffect(() => {
        if (timerResponse === "TIMER_RESPONSE_INIT") initConfigInputs();
    }, []);

    const submit = () => {
        submitConfig();
    };

    const onBlur = () => {
        submit();
    };

    useEffect(() => {
        if (timerResponse === "TIMER_RESPONSE_SUCCESS") {
            alertService.success("Timer Updated", { autoClose: true });
        }
    }, [timerResponse]);

    const selectClick = (questionKey, option) => {
        const { activeFields } = inputFields;

        const possibleValues = [
            "None",
            "Some",
            "More"
        ];

        const tempQuestion = inputFields.otherInputs.find(input => input.key === questionKey);

        if (tempQuestion.type === "select") {
            let valueIndex = possibleValues.findIndex(val => val === activeFields[questionKey][option.key]);
            valueIndex = possibleValues.length - 1 === valueIndex ? 0 : valueIndex + 1;
            activeFields[questionKey][option.key] = possibleValues[valueIndex];
            updateInputs({ activeFields });

        } else {
            activeFields[questionKey] = option.key;

            updateInputs({ activeFields });
        }
        if (_.isEqual(activeFields, inputFields.activeFields))
            submit();
    };

    return (
        <>
            {
                (inputFields && inputFields.timersInputs && inputFields.timersInputs?.length) ? (
                    <div styleName="inputs-wrap">
                        {
                            inputFields.timersInputs.map(timersInput => (
                                <InputTime
                                    key={timersInput.key}
                                    value={timersInput.type === "time"
                                        ? totalSeconds(getTime(inputFields?.activeFields[timersInput.key])).toString()
                                        : inputFields?.activeFields[timersInput.key].toString()}
                                    type={timersInput.type}
                                    name={timersInput.key}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    label={timersInput.title}
                                />
                            ))
                        }
                    </div>
                ) : null
            }
            {
                (inputFields.otherInputs && inputFields.otherInputs?.length) && (
                    <div styleName="inputs-wrap inputs-wrap--others">
                        {
                            inputFields.otherInputs.map(otherInput => (
                                <div styleName="input-wrap input-wrap--fullwidth" key={otherInput.key}>
                                    <span styleName="input-wrap__title">{otherInput.title}</span>
                                    {
                                        (otherInput.type === "select") ? (
                                            (otherInput.options && otherInput.options?.length) && (
                                                <div styleName="input-wrap__options">
                                                    {
                                                        otherInput.options.map(option => (
                                                            <span onClick={() => selectClick(otherInput.key, option)} styleName={classNames("input-wrap__select-option", {
                                                                "input-wrap__select-option--more": inputFields?.activeFields[otherInput.key][option.key] === "More",
                                                                "input-wrap__select-option--some": inputFields?.activeFields[otherInput.key][option.key] === "Some"
                                                            })} key={option.key}>{option.title}</span>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        ) : (
                                            (otherInput.options && otherInput.options?.length) && (
                                                <div styleName="input-wrap__options">
                                                    {
                                                        otherInput.options.map(option => (
                                                            <label key={option.key} styleName="input-wrap__option">
                                                                <input
                                                                    onClick={() => selectClick(otherInput.key, option)}
                                                                    name={otherInput.key}
                                                                    type="radio"
                                                                    defaultChecked={inputFields?.activeFields[otherInput.key] === option.key}
                                                                    checked={inputFields?.activeFields[otherInput.key] === option.key}
                                                                />
                                                                <span styleName="input-wrap__circle"></span>
                                                                <span styleName="input-wrap__option-title">{option.title}</span>
                                                            </label>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                            ))
                        }
                    </div>
                )
            }
            {
                (inputFields.gestureInputs && inputFields.gestureInputs?.length) && (
                    <div styleName="inputs-wrap">
                        {
                            inputFields.gestureInputs.map(gestureInput => (
                                <div styleName="input-wrap input-wrap--fullwidth" key={gestureInput.key}>
                                    <span styleName="input-wrap__title">{gestureInput.title}</span>
                                    {
                                        (gestureInput.options && gestureInput.options?.length) && (
                                            <div styleName="input-wrap__options">
                                                {
                                                    gestureInput.options.map(option => (
                                                        <label onClick={() => {
                                                            if(option.key === "dark-mode") {
                                                                currentTheme(theme === "dark");
                                                            }
                                                        }} styleName={classNames("input-wrap__select-option", {
                                                            "input-wrap__select-option--active": (option.key === "dark-mode" &&theme === "dark")
                                                        })} key={option.key}>{option.title}</label>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                </div>

                            ))
                        }
                    </div>
                )
            }
        </>
    );
};

const mapStateToProp = state => ({
    configTabsState: state.configWorkout,
    configInputsState: state.configInputs,
    timerResponse: state.timerResponse,
    theme: state.theme
});

const mapDispatchToProps = dispatch => ({
    updateInputs: data => dispatch(updateInputs(data)),
    submitConfig: () => dispatch(submitConfig()),
    initConfigInputs: () => dispatch(initConfigInputs()),
    currentTheme: (data) => dispatch(currentTheme(data))
});

export default connect(mapStateToProp, mapDispatchToProps)(ConfigureFormInputs);
