"use strict";
import "./Style/styles.scss";
import store from "./Redux/store";
import BoxingApp from "./BoxingApp";
import { Router } from "react-router";
import { hot } from "react-hot-loader";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import AppMetaTags from "Services/appMetaTagService";

const history = createBrowserHistory();

const { pathname } = window.location;

const App = () => {
    return (
        <Provider store={store}>
            <Router history={history}>
                <AppMetaTags pathname={pathname} />
                <BoxingApp />
            </Router>
        </Provider>
    );
};

export default hot(module)(BoxingApp);

ReactDOM.render(<App />, document.getElementById("app"));
