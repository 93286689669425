import React from "react";
import {Redirect} from "react-router";

const BoxingRoute = ({ component: Component, path, isPrivate, ...rest }) => {

    if (isPrivate) {
        const token = localStorage.getItem("token");
        if(token) {
            return <Component {...rest} />;
        }else{
            return <Redirect from="*" to="/login" />;
        }
    } else {
        return <Component {...rest} />;
    }
};

export default BoxingRoute;
