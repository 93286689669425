import { SHARE_MODAL } from "../actionTypes";

export const shareModalReducer = (state = false, action) => {
    switch (action.type) {
        case SHARE_MODAL:
            return action.payload || false;
        default:
            return state;
    }
};
