import "./style.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const Combos = ({ combos, comboMove }) => {
    const [getCombos, setCombos] = useState(false);

    useEffect(() => {
        const distinct = combos && combos.reduce((total, combo) => {
            const isHave = total[total.length - 1]?.shortName === combo.shortName;
            let times = combo.times || 1;

            if (!isHave) {
                return [...total, { ...combo, times }];
            }

            times = total[total.length - 1]?.times + 1;
            let tempTotal = total;
            tempTotal[total.length - 1] = { ...tempTotal[total.length - 1], times };

            return tempTotal;
        }, []);
        setCombos(distinct);
    }, []);

    return (
        <div styleName="combos">
            {
                (comboMove && getCombos) && getCombos.map((combo, index) => !combo.isAlert && (
                    <Link styleName="combo" key={`${combo.shortName}-${index}`} to={!combo.stype.includes("OTHER") ? `primer/${comboMove[combo.shortName]?.longName}` : "#!"}>
                        {
                            !combo.stype.includes("OTHER") && (
                                <span styleName="combo__times">{combo.times}X</span>
                            )
                        }
                        <span styleName="combo__label">
                            {comboMove[combo.shortName]?.longName}
                            {
                                !combo.stype.includes("OTHER") && <i className="fas fa-play"></i>
                            }
                        </span>
                    </Link>
                ))
            }
        </div>
    );
};

export default Combos;
