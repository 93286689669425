import "./style.scss";

const ConfirmationModal = ({ open, onClose, onConfirm }) => {
    const confirm = () => {
        onConfirm();
        onClose(!open);
    };
    return (
        <div styleName="confirmation-modal--popup">
            <div styleName="confirmation-modal">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40.0344 0C40.0238 0 40.0097 0.00176558 39.9991 0.00176558C39.9868 0.00176558 39.9762 0 39.9638 0C17.8941 0 0 17.8923 0 39.9656C0 62.0353 17.8941 79.9294 39.9638 79.9294C39.9762 79.9294 39.9868 79.9294 39.9991 79.9294C40.0097 79.9294 40.0238 79.9294 40.0344 79.9294C62.1041 79.9294 79.9982 62.0353 79.9982 39.9656C80 17.8923 62.1059 0 40.0344 0ZM40.0344 68.4884C40.0238 68.4884 40.0097 68.4867 39.9991 68.4867C39.9868 68.4867 39.9762 68.4884 39.9638 68.4884C36.3514 68.4884 33.4223 65.5611 33.4223 61.947C33.4223 58.3364 36.3514 55.4091 39.9638 55.4091C39.9762 55.4091 39.9868 55.4091 39.9991 55.4091C40.0097 55.4091 40.0238 55.4091 40.0344 55.4091C43.645 55.4091 46.5777 58.3364 46.5777 61.947C46.5777 65.5593 43.6503 68.4884 40.0344 68.4884ZM49.4149 18.6409C48.9877 25.1683 46.0038 44.5808 46.0038 44.5808C46.0038 47.8542 43.3219 50.5061 40.0486 50.5061C40.0309 50.5061 40.0132 50.5061 39.9973 50.5061C39.9815 50.5061 39.962 50.5061 39.9461 50.5061C36.6745 50.5061 33.9926 47.8506 33.9926 44.5808C33.9926 44.5808 31.0106 25.1683 30.5833 18.6409C30.3714 15.3729 32.875 9.30458 39.9479 9.30458C39.9656 9.30458 39.9832 9.30635 39.9991 9.30635C40.015 9.30635 40.0327 9.30458 40.0503 9.30458C47.1214 9.30458 49.6286 15.3729 49.4149 18.6409Z" fill="#AA1C23"/>
                </svg>

                <p>Do you really want to stop the workout?</p>
                <div styleName="confirmation-modal__actions">
                    <button onClick={() => onClose(!open)} styleName="confirmation-modal__action-btn">Cancel</button>
                    <button onClick={() => confirm()} styleName="confirmation-modal__action-btn confirmation-modal__action-btn--confirm">Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
