import "./Style/styles.scss";
import Layout from "./Layout";
import { Suspense } from "react";
import routes from "Common/routes";
import BoxingRoute from "./BoxingRoute";
import { Redirect, Route, Switch } from "react-router";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const App = props => (
    <Layout location={location} {...props}>
        <Suspense fallback={null}>
            <Route render={() => (
                <TransitionGroup>
                    <CSSTransition
                        key={location.key}
                        timeout={300}
                        classNames={"fade"}
                    >
                        <Switch>
                            {routes && routes.map(route => (
                                <BoxingRoute
                                    key={route.path}
                                    exact={true}
                                    path={route.path}
                                    component={route.component}
                                    isPrivate={route.isPrivate}
                                />
                            ))}
                            <Redirect from="*" to="/" />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            )}/>
        </Suspense>
    </Layout>
);

export default App;
