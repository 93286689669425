import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import "./style.scss";

const InputTime = ({ name, label, value, onChange, type, onBlur }) => {
    const [isRealActive, setRealActive] = useState(false);
    const [timeValue, setTimeValue] = useState("0000");

    useEffect(() => {
        let tempValue = value;
        if (tempValue > 60) {
            const totalMinutes = parseInt(tempValue / 60);
            const totalSeconds = parseInt(tempValue % 60);
            tempValue = totalMinutes.toString().padStart(2, "0").concat(totalSeconds.toString().padStart(2, "0"));
        } else {
            tempValue = tempValue.toString().padStart(4, "0");
        }

        setTimeValue(tempValue);
    }, [value]);

    const onchange = event => {
        const { value, name } = event.target;
        let tempVal = value;
        if (type === "time") tempVal = tempVal * 1000;

        if (/^\d+$/.test(tempVal) || tempVal === "") {
            onChange(tempVal, name);
        }
    };

    const keyPress = event => (event.key === "Enter") && event.target.blur();

    const onblur = event => {
        setRealActive(false);

        if (onBlur)
            onBlur(event);
    };

    return (
        <label styleName="input-wrap">
            <span styleName="input-wrap__title">{label}</span>
            {
                type === "time" ? (
                    <>
                        {
                            isRealActive ? (
                                <input
                                    name={name}
                                    styleName="input-wrap__input"
                                    type="number"
                                    onBlur={onblur}
                                    onClick={event => event.target.select()}
                                    onKeyPress={keyPress}
                                    onChange={onchange}
                                    min={value}
                                    value={value}
                                />
                            ) : (
                                <input
                                    name={name}
                                    styleName="input-wrap__input"
                                    type="text"
                                    onKeyPress={keyPress}
                                    onFocus={() => setRealActive(true)}
                                    onChange={onchange}
                                    value={`${timeValue.substring(0, 2)}${timeValue.length > 2 ? ":": ""}${timeValue.substring(2, 4)}`}
                                />
                            )
                        }
                    </>
                ) : (
                    <input
                        min="0"
                        max="10"
                        onKeyPress={keyPress}
                        styleName="input-wrap__input"
                        onChange={onchange}
                        onBlur={onblur}
                        onFocus={(event) => event.target.select()}
                        name={name}
                        type={type}
                        value={value}
                    />
                )
            }
        </label>
    );
};

InputTime.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string || PropTypes.number,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

InputTime.defaultProps = {
    label: "",
    value: "",
    onChange: () => {},
    onBlur: () => {}
};

export default InputTime;
