export const TOGGLE_NAVIGATION = "TOGGLE_NAVIGATION";
export const ABOUT_MODAL = "ABOUT_MODAL";
export const BOOK_MARK_MODAL = "BOOK_MARK_MODAL";
export const SHARE_MODAL = "SHARE_MODAL";
export const SHARE_TOGGLE = "SHARE_TOGGLE";

export const ABOUT_TEXT = "ABOUT_TEXT";
export const ABOUT_LOADING = "ABOUT_LOADING";
export const ABOUT_SUCCESS = "ABOUT_SUCCESS";

export const CURRENT_THEME = "CURRENT_THEME";

export const START_TIME = "START_TIME";
export const CURRENT_TIME = "CURRENT_TIME";
export const STOP_TIME = "STOP_TIME";
export const RUNNING_TIME = "RUNNING_TIME";
export const INIT_TIME = "INIT_TIME";
export const NEXT_STEP = "NEXT_STEP";
export const UPDATE_SIZE = "UPDATE_SIZE";
export const UPDATE_TIME = "UPDATE_TIME";
export const WORK_OUT_ENDED = "WORK_OUT_ENDED";
export const TIMER_RESPONSE_SUCCESS = "TIMER_RESPONSE_SUCCESS";
export const TIMER_RESPONSE_WORKING = "TIMER_RESPONSE_WORKING";


export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const CONFIG_TAB = "CONFIG_TAB";

export const INIT_CONFIG_INPUTS = "INIT_CONFIG_INPUTS";
export const SAVE_CONFIG_INPUTS = "SAVE_CONFIG_INPUTS";
export const DEFAULT_CONFIG_INPUTS_UPDATE = "DEFAULT_CONFIG_INPUTS_UPDATE";
export const CONFIG_INPUTS = "CONFIG_INPUTS";
export const CONFIG_INPUTS_UPDATE = "CONFIG_INPUTS_UPDATE";
export const COMBO_GENRATE_SUCCESS = "COMBO_GENRATE_SUCCESS";
export const RESET_INPUTS = "RESET_INPUTS";
export const RESET_INPUT_FIELDS = "RESET_INPUT_FIELDS";
export const SUBMIT_CONFIG = "SUBMIT_CONFIG";

export const PREVIEW_COMBOS = "PREVIEW_COMBOS";
export const UPDATE_PREVIEW_COMBOS = "UPDATE_PREVIEW_COMBOS";

export const GET_BOXING_PRIMER = "GET_BOXING_PRIMER";
export const GET_BOXING_PRIMER_MOVE = "GET_BOXING_PRIMER_MOVE";
export const GET_BOXING_PRIMER_SUCCESS = "GET_BOXING_PRIMER_SUCCESS";

export const GET_SEARCH_SUGGESTION = "GET_SEARCH_SUGGESTION";
export const SELECT_SEARCH_SUGGESTION = "SELECT_SEARCH_SUGGESTION";

export const PLAY_AUDIO = "PLAY_AUDIO";

export const GET_CURRENT_PRIMER_MOVE = "GET_CURRENT_PRIMER_MOVE";
