import "./style.scss";
import PropTypes from "prop-types";
import classnames from "classnames";
import { getTime } from "Common/timerConfig";
import { getLocalAsset } from "Common/url";
import { connect } from "react-redux";

const SharingCard = ({
    imageSrc,
    type,
    index,
    isSimple,
    values,
    roundsSpent,
    shareHandle,
    totalTime,
    activeTime,
    lockedCombos,
    onClickHandle,
    bookMark,
    configTabsState,
    configInputsState
}) => {
    const currentTabTemp = configTabsState && configTabsState.find(tab => tab.isActive);
    const inputFields = configInputsState[currentTabTemp.key];
    const { activeFields } = inputFields || {};
    const bookMarkActiveTime = activeFields?.roundTime * activeFields?.rounds;
    const { moves } = values;
    const getTotalTime = getTime(totalTime);
    const getActiveTime = getTime(activeTime);
    const getActiveTimeBookMark = getTime(bookMarkActiveTime);

    const getColor = (level, label) => {
        const combinations = {
            Basic: 1,
            Slow: 1,
            Low: 1,
            Medium: 2,
            Intermediate: 2,
            Advanced: 3,
            Fast: 3,
            High: 3
        };

        return getLocalAsset(`${label}-${combinations[level]}@2x.png`);
    };

    return (
        <div id={`sharing-card-${index}`} onClick={() => onClickHandle(index)} styleName={classnames("sharing-card-wrapper", {
            "sharing-card-wrapper--simple-design": isSimple,
            "sharing-card-wrapper-bookmark": bookMark
        })}>
            {
                imageSrc && <img styleName={classnames("sharing-card-wrapper__img", {
                    "sharing-card-wrapper__img--type-1": type === 1
                })} src={getLocalAsset(imageSrc)} />
            }
            <div styleName={classnames("sharing-card-deatils", {
                "sharing-card-deatils__type-2": type === 2 || bookMark
            })}>
                <div styleName={classnames("sharing-card-deatils__header", {"sharing-card-deatils__header--bookMark":bookMark})}>
                    <h3 styleName="sharing-card-deatils__heading">Combat<span>Go</span></h3>
                    {
                        !isSimple && <span styleName="sharing-card-deatils__rounds">{bookMark ? (<span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.334 2H4.66732C3.93398 2 3.33398 2.6 3.33398 3.33333V14L8.00065 12L12.6673 14V3.33333C12.6673 2.6 12.0673 2 11.334 2Z" fill="white"/>
                        </svg></span>)
                            :"ROUNDS"}</span>
                    }
                </div>
                {
                    (type === 1 || bookMark) && (
                        <div styleName="segments-around-icons">
                            <span>
                                <img src={getColor(values.complexity, "Complexity")} />
                            </span>
                            <span>
                                <img src={getColor(values.intensity, "Intensity")} />
                            </span>
                            <span>
                                <img src={getColor(values.skillLevel, "Skill Level")} />
                            </span>
                        </div>
                    )
                }
                <div styleName={classnames("boxing-configs", {
                    "boxing-configs--type-1": type === 2,
                    "boxing-configs--book-mark": bookMark
                })}>
                    <div styleName="boxing-config">
                        <span styleName="boxing-config__value">{roundsSpent}</span>
                        <span styleName="boxing-config__label">ROUNDS</span>
                    </div>
                    {
                        !bookMark && (
                            <div styleName="boxing-config">
                                <span styleName="boxing-config__value">{lockedCombos}</span>
                                <span styleName="boxing-config__label">COMBOS</span>
                            </div>
                        )
                    }
                    <div styleName="boxing-config">
                        <span styleName="boxing-config__value">
                            {bookMark? (`${getActiveTimeBookMark.minutes < 10 ? "0"+getActiveTimeBookMark.minutes : getActiveTimeBookMark.minutes}:${getActiveTimeBookMark.seconds < 10 ? "0"+getActiveTimeBookMark.seconds : getActiveTimeBookMark.seconds}`):
                                (`${getActiveTime.minutes < 10 ? "0"+getActiveTime.minutes : getActiveTime.minutes}:${getActiveTime.seconds < 10 ? "0"+getActiveTime.seconds : getActiveTime.seconds}`)}
                        </span>
                        <span styleName="boxing-config__label">ACTIVE TIME</span>
                    </div>
                    <div styleName="boxing-config">
                        <span styleName="boxing-config__value">
                            {`${getTotalTime.minutes < 10 ? "0"+getTotalTime.minutes : getTotalTime.minutes}:${getTotalTime.seconds < 10 ? "0"+getTotalTime.seconds : getTotalTime.seconds}`}
                        </span>
                        <span styleName="boxing-config__label">TOTAL TIME</span>
                    </div>
                </div>
                {
                    (type === 1 || bookMark) && (
                        <div styleName="boxing-workouts">
                            <span styleName={classnames("boxing-workout", `boxing-workout--${moves["Punch"]}`)}>PUNCHES</span>
                            <span styleName={classnames("boxing-workout", `boxing-workout--${moves["Defense"]}`)}>DEFENSE</span>
                            <span styleName={classnames("boxing-workout", `boxing-workout--${moves["Kick"]}`)}>KICKS</span>
                            <span styleName={classnames("boxing-workout", `boxing-workout--${moves["Footwork"]}`)}>FOOTWORK</span>
                            <span styleName={classnames("boxing-workout", `boxing-workout--${moves["Knees & Elbows"]}`)}>KNEES &amp; ELBOWS</span>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

SharingCard.defaultProps = {
    imageSrc: "",
    type: 1,
    isSimple: false,
    values: {},
    index: -0,
    roundsSpent: 0,
    totalTime: 0,
    lockedCombos: 0,
    activeTime: 0,
    onClickHandle: () => null
};

SharingCard.propTypes = {
    imageSrc: PropTypes.string,
    type: PropTypes.number,
    isSimple: PropTypes.bool,
    values: PropTypes.shape({}),
    activeTime: PropTypes.number,
    index: PropTypes.number,
    roundsSpent: PropTypes.number,
    totalTime: PropTypes.number,
    lockedCombos: PropTypes.number,
    onClickHandle: PropTypes.func
};


const mapStateToProp = state => ({
    configTabsState: state.configWorkout,
    configInputsState: state.configInputs
});

export default connect(mapStateToProp, null)(SharingCard);

