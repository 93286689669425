import "./style.scss";
import PreviewCombos from "Components/PreviewCombos";

const PreviewCombosView = () => {
    return (
        <PreviewCombos />
    );
};

export default PreviewCombosView;
