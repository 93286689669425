import "./style.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import { toggleNavigation, playAudio, currentTheme } from "Redux/actionCreators";
import { Link } from "react-router-dom";

const Header = ({
    toggleNavigation,
    toggleNavigationState,
    currentPrimerMoveState,
    playAudioState,
    playAudio,
    backPath,
    title,
    isHeader,
    theme,
    currentTheme
}) => {
    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };
    return (
        <header styleName={classNames("header", {
            "header__center": !isHeader
        })}>
            <div className="container">
                {
                    isHeader ? (

                        <>
                            <button onClick={() => toggleNavigation(!toggleNavigationState)} styleName="header__toggler">
                                <span/><span/><span/>
                            </button>

                            <div styleName="header__links">
                                <button onClick={() => currentTheme(theme === "dark")} styleName="header__link">
                                    {
                                        theme !== "light" ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24"/><path d="M12,9c1.65,0,3,1.35,3,3s-1.35,3-3,3s-3-1.35-3-3S10.35,9,12,9 M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5 S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1 s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0 c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95 c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41 L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41 s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06 c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z"/></svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24"/><path d="M9.37,5.51C9.19,6.15,9.1,6.82,9.1,7.5c0,4.08,3.32,7.4,7.4,7.4c0.68,0,1.35-0.09,1.99-0.27C17.45,17.19,14.93,19,12,19 c-3.86,0-7-3.14-7-7C5,9.07,6.81,6.55,9.37,5.51z M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36 c-0.98,1.37-2.58,2.26-4.4,2.26c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z"/></svg>
                                        )
                                    }
                                </button>
                                <button onClick={() => playAudio(!playAudioState)} styleName="header__link"><i className={classNames("far", {
                                    "fa-volume-up": playAudioState,
                                    "fa-volume-mute": !playAudioState,
                                })}></i></button>
                                <button onClick={() => toggleFullscreen()} styleName="header__link">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.25 15V20.25H15" stroke={theme === "light" ? "#1E1F20" : "#fff"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M19.7719 19.7705L14.25 14.25" stroke={theme === "light" ? "#1E1F20" : "#fff"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3.75 9V3.75H9" stroke={theme === "light" ? "#1E1F20" : "#fff"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M4.22827 4.22949L9.75015 9.74997" stroke={theme === "light" ? "#1E1F20" : "#fff"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15 3.75H20.25V9" stroke={theme === "light" ? "#1E1F20" : "#fff"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M19.7705 4.22803L14.25 9.7499" stroke={theme === "light" ? "#1E1F20" : "#fff"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M9 20.25H3.75V15" stroke={theme === "light" ? "#1E1F20" : "#fff"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M4.22949 19.7719L9.74997 14.25" stroke={theme === "light" ? "#1E1F20" : "#fff"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Link styleName="header__back-link" to={backPath || "#"}><i className="fas fa-chevron-left"></i></Link>
                            <h4 styleName="header__title">{(title === "fetching..." && currentPrimerMoveState) ? currentPrimerMoveState.shortName : (title || "")}</h4>
                        </>
                    )
                }
            </div>
        </header>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        toggleNavigation: toggle => dispatch(toggleNavigation(toggle)),
        playAudio: data => dispatch(playAudio(data)),
        currentTheme: (data) => dispatch(currentTheme(data))
    };
};

const mapStateToProps = state => {
    return {
        currentPrimerMoveState: state.currentPrimerMove,
        toggleNavigationState: state.toggleNavigation,
        playAudioState: state.playAudio,
        theme: state.theme
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
