import { useEffect } from "react";

const useEventSystem = (eventName, callback) => {
    useEffect(() => {
        const component = {
            [`on${eventName}`]: callback
        };
        eventSystem.subscribe(component, eventName);
        return () => eventSystem.unsubscribe(component, eventName);
    }, [eventName, callback]);
};

export default useEventSystem;
