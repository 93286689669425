import combinedReducers from "./reducers";
import { mainMiddleware } from "./middlewares";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, compose, applyMiddleware} from "redux";

const devTools = process.env.DEBUG === "true" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : null;
var enhancer;
if (process.env.DEBUG === "true") {
    enhancer = compose(
        applyMiddleware(mainMiddleware),
        devTools);
} else {
    enhancer = composeWithDevTools(
        applyMiddleware(mainMiddleware),
    );
}

export default createStore(combinedReducers, enhancer);
