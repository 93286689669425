import "./style.scss";
import { useEffect, useRef } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import Lottie from "react-lottie";
import playPauseLoading from "Common/icons/rotation(loading).json";
import playPauseLoadingDark from "Common/icons/rotation(loading)Dark.json";
import play from "Common/icons/play.json";
import playDark from "Common/icons/playDark.json";
import pause from "Common/icons/pause.json";
import pauseDark from "Common/icons/pauseDark.json";
import ConfirmationModal from "Components/ConfirmationModal";
import { startTimer, stopTimer, nextStep } from "Redux/actionCreators";
import { useState } from "react";
import { useScreenAwake } from "Common/Hooks/useScreenAwake";

const ActionButtons = ({
    startTimer,
    stopTimer,
    timerResponse,
    currentTime,
    nextStep,
    theme
}) => {
    const [confirmation, setConfirmation] = useState(false);
    const playButtonRef = useRef(null);
    const { rounds, currentRound, isStopped, isfocued } = currentTime;
    const [, setScreenAwake] = useScreenAwake();

    // useEffect(() => {
    //     if (playButtonRef?.current) {
    //         if (!rounds?.length || timerResponse !== "TIMER_RESPONSE_WORKING") {
    //             playButtonRef.current.pause(20, 120);
    //         } else if (!isStopped) {
    //             playButtonRef.current.pause(120, 240);
    //         } else {
    //             playButtonRef.current.pause(20, 120);
    //         }
    //     }
    // }, [playButtonRef, isStopped, rounds]);

    return (
        <>
            <section styleName="action-btn-section">
                <div styleName={classNames("action-btns", { "action-btns--active": isfocued })}>
                    <button disabled={!isfocued} styleName="btn-stop" onClick={() => {
                        if (!isStopped) startTimer();
                        setConfirmation(true);
                        setScreenAwake(false);
                    }}>
                        <i className="fas fa-stop"></i>
                    </button>
                    <button disabled={(!rounds?.length || timerResponse !== "TIMER_RESPONSE_WORKING")} styleName={`btn-play ${!isStopped ? "active" : ""}`} onClick={() => {
                        startTimer();
                        setScreenAwake(true);
                    }}>
                        <Lottie
                            ref={(animation) => {
                                playButtonRef.current = animation;
                            }}
                            options={{
                                animationData: (!rounds?.length || timerResponse !== "TIMER_RESPONSE_WORKING") ? theme === "dark" ? playPauseLoadingDark : playPauseLoading : (isStopped ? theme === "dark" ? pauseDark : pause : theme === "dark" ? playDark : play),
                                autoPlay: (!rounds?.length || timerResponse !== "TIMER_RESPONSE_WORKING"),
                                loop: (!rounds?.length || timerResponse !== "TIMER_RESPONSE_WORKING")
                            }}
                        />
                    </button>
                    <button disabled={!isfocued || !(currentRound  < rounds.length - 1)} styleName="btn-stop" onClick={() => nextStep()}>
                        <span styleName="btn__icon">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.7325 20.2501C4.4447 20.2495 4.16199 20.1741 3.91219 20.0312C3.34969 19.7124 3 19.0937 3 18.422V4.82821C3 4.15461 3.34969 3.53774 3.91219 3.21899C4.16794 3.07201 4.45848 2.99642 4.75343 3.00013C5.04839 3.00384 5.33693 3.08671 5.58891 3.24008L17.2069 10.1945C17.449 10.3463 17.6486 10.5571 17.787 10.8072C17.9253 11.0572 17.9979 11.3384 17.9979 11.6241C17.9979 11.9099 17.9253 12.191 17.787 12.4411C17.6486 12.6912 17.449 12.902 17.2069 13.0538L5.58703 20.0101C5.32917 20.166 5.03382 20.2489 4.7325 20.2501Z" fill={isfocued ? "#449BB1" : theme === "dark" ? "#828282" : "#E0E0E0"}/>
                                <path d="M20.9982 19.875H18.7482C18.5493 19.875 18.3585 19.796 18.2178 19.6553C18.0772 19.5147 17.9982 19.3239 17.9982 19.125V4.125C17.9982 3.92609 18.0772 3.73532 18.2178 3.59467C18.3585 3.45402 18.5493 3.375 18.7482 3.375H20.9982C21.1971 3.375 21.3878 3.45402 21.5285 3.59467C21.6692 3.73532 21.7482 3.92609 21.7482 4.125V19.125C21.7482 19.3239 21.6692 19.5147 21.5285 19.6553C21.3878 19.796 21.1971 19.875 20.9982 19.875Z" fill={theme === "dark" ? "#828282" : "#E0E0E0"}/>
                            </svg>
                        </span>
                    </button>
                </div>
            </section>
            {
                confirmation && <ConfirmationModal open={confirmation} onClose={setConfirmation} onConfirm={stopTimer} />
            }
        </>
    );
};

const mapStateToProp = state => {
    return {
        currentTime: state.currentTime,
        theme: state.theme,
        timerResponse: state.timerResponse
    };
};

const mapDispatchToProps = dispatch => {
    return {
        stopTimer: () => dispatch(stopTimer()),
        startTimer: () => dispatch(startTimer()),
        nextStep: () => dispatch(nextStep())
    };
};

export default connect(mapStateToProp, mapDispatchToProps)(ActionButtons);
