import "./style.scss";
import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { alertService } from "Services/alertService";
import {
    initConfigInputs,
    initTimer,
    submitConfig,
    workoutEnded,
    runningTimer,
    updateSize
} from "Redux/actionCreators";
import { getTime } from "Common/timerConfig";
import { useScreenAwake } from "Common/Hooks/useScreenAwake";
import { useWindowSize } from "Common/Hooks/useWindowSize";
import classnames from "classnames";
import { useLocation } from "react-router";

const CircularProgressBar = ({
    sqSize,
    strokeWidth,
    initTimer,
    submitConfig,
    workoutEnded,
    initConfigInputs,
    timerResponse,
    runningTimer,
    currentTime,
    updateSize,
    theme
}) => {

    const [timerSqSize, setTimerSqSize] = useState(sqSize);
    const [radius, setRadius] = useState((timerSqSize - strokeWidth) / 2);
    const [innerRadius, setInnerRadius] = useState((radius - strokeWidth) - 5);
    const [viewBox, setViewBox] = useState(`0 0 ${timerSqSize} ${timerSqSize}`);
    const [, setScreenAwake] = useScreenAwake();
    const location = useLocation();
    const queryStrings = new URLSearchParams(location?.search);
    const query = queryStrings.get("workoutid");
    const timerRef = useRef();

    const { isMobile, width } = useWindowSize();

    useEffect(() => {
        if (timerResponse === "TIMER_RESPONSE_INIT") {

            initConfigInputs(query);

            initTimer({radius, innerRadius, strokeWidth});
        }
        return () => setScreenAwake(false);
    }, []);

    useEffect(() => {
        const tempRadius = (timerSqSize - strokeWidth) / 2;
        const tempInnerRadius = (tempRadius - strokeWidth) - 5;

        setRadius(tempRadius);
        setInnerRadius(tempInnerRadius);
        setViewBox(`0 0 ${timerSqSize} ${timerSqSize}`);

        updateSize({radius: tempRadius, innerRadius: tempInnerRadius, strokeWidth});
    }, [sqSize, timerSqSize]);

    useEffect(() => {
        if (timerResponse === "TIMER_RESPONSE_SUCCESS") {
            alertService.success("Timer Updated", { autoClose: true });
        }
    }, [timerResponse]);

    useEffect(() => {
        const timer = setTimeout(_ => {
            runningTimer();
        }, 50);

        if (currentTime.totalTime <= 0) {
            setScreenAwake(false);
            clearTimeout(timer);
        }
    }, [currentTime]);

    useEffect(() => {
        if (currentTime.workoutEnded) {
            workoutEnded();
            submitConfig();
        }
    }, [currentTime]);

    useEffect(() => {
        if (timerRef?.current && !isMobile) {
            if (width < 1100) setTimerSqSize(timerRef?.current?.getBoundingClientRect().height - 80);
            else setTimerSqSize(timerRef?.current?.getBoundingClientRect().height);
        }
        else setTimerSqSize(sqSize);
    }, [timerRef, isMobile]);

    const {
        roundsSpent,
        statusRound,
        totalRounds,
        stepTime,
        totalTime,
        innerDashOffset,
        innerDashArray,
        dashOffset,
        dashArray,
    } = currentTime;

    const getTotalTime = getTime(totalTime);
    const getStepTime =  getTime(stepTime);

    return (
        <div ref={timerRef} style={isMobile ? {width: `${timerSqSize}px`, height: `${timerSqSize}px`} : {}} styleName={classnames("svg-wrap", {
            "svg-wrap--desktop": !isMobile
        })}>
            <svg
                width={timerSqSize}
                height={timerSqSize}
                styleName="main-svg"
                viewBox={viewBox}>
                <circle
                    styleName="outer-circle-background"
                    cx={timerSqSize / 2}
                    cy={timerSqSize / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`} />
                <defs>
                    <linearGradient id="outer-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stopColor={theme === "dark" ? "#449BB1" :"#2974A3"}/>
                    </linearGradient>
                </defs>
                <circle
                    styleName="outer-circle-progress"
                    cx={timerSqSize / 2}
                    cy={timerSqSize / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}
                    transform={`rotate(-90 ${timerSqSize / 2} ${timerSqSize / 2})`}
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset
                    }} />
                <circle
                    styleName="inner-circle-background"
                    cx={timerSqSize / 2}
                    cy={timerSqSize / 2}
                    r={innerRadius}
                    strokeWidth={`${strokeWidth}px`} />
                <defs>
                    <linearGradient id="inner-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stopColor="#8EA94B"/>
                    </linearGradient>
                </defs>
                <circle
                    styleName="inner-circle-progress"
                    cx={timerSqSize / 2}
                    cy={timerSqSize / 2}
                    r={innerRadius}
                    strokeWidth={`${strokeWidth}px`}
                    transform={`rotate(-90 ${timerSqSize / 2} ${timerSqSize / 2})`}
                    style={{
                        strokeDasharray: innerDashArray,
                        strokeDashoffset: innerDashOffset
                    }} />
                <circle
                    cx={timerSqSize / 2}
                    cy={timerSqSize / 2}
                    r={innerRadius - (strokeWidth + 3)}
                    stroke={theme === "dark" ? "#BDBDBD" : "#1E1F20"}
                    strokeWidth="2"
                    strokeDasharray="0 15"
                    strokeLinecap="round"
                    fill="transparent" />
            </svg>
            <div styleName="boxing-timers__text">
                {
                    (statusRound === "Prepare" || statusRound === "Rest" || statusRound === "stopped") ? (
                        <span styleName="status-text">
                            { statusRound }
                        </span>
                    ) : (
                        <span styleName="status-text__round">
                            { isMobile ? `Round ${roundsSpent} / ${totalRounds}` : `Round ${roundsSpent}/${totalRounds}` }
                        </span>
                    )
                }
                <span styleName="timer-text">
                    {`${getStepTime.minutes < 10 ? "0"+getStepTime.minutes : getStepTime.minutes}:${getStepTime.seconds < 10 ? "0"+getStepTime.seconds : getStepTime.seconds}`}
                </span>
                <span styleName="total-time">
                    {`${getTotalTime.minutes < 10 ? "0"+getTotalTime.minutes : getTotalTime.minutes}:${getTotalTime.seconds < 10 ? "0"+getTotalTime.seconds : getTotalTime.seconds}`}
                </span>
            </div>
        </div>
    );
};

const mapStateToProp = state => {
    return {
        currentTime: state.currentTime,
        timerResponse: state.timerResponse,
        configInputs: state.configInputs,
        theme: state.theme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initTimer: data => dispatch(initTimer(data)),
        runningTimer: data => dispatch(runningTimer(data)),
        updateSize: data => dispatch(updateSize(data)),
        initConfigInputs: (data) => dispatch(initConfigInputs(data)),
        submitConfig: () => dispatch(submitConfig()),
        workoutEnded: () => dispatch(workoutEnded())
    };
};

CircularProgressBar.defaultProps = {
    sqSize: 310,
    strokeWidth: 10
};

export default connect(mapStateToProp, mapDispatchToProps)(CircularProgressBar);
