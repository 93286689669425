import { SHARE_TOGGLE } from "../actionTypes";

export const shareToggleReducer = (state = false, action) => {
    switch (action.type) {
        case SHARE_TOGGLE:
            return action.payload || false;
        default:
            return state;
    }
};
