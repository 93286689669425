import "./style.scss";
import { connect } from "react-redux";

const CombosAndMoves = ({currentTime}) => {
    const { currentCombo, totalCombos, currentUIMove, totalUIMoves } = currentTime;

    return (
        <div styleName="combos_and_moves">
            <div styleName="card">
                <span styleName="card__title">Combos</span>
                <span styleName="card__value">{currentCombo}/{totalCombos}</span>
            </div>
            <div styleName="card">
                <span styleName="card__title">Moves</span>
                <span styleName="card__value">{currentUIMove}/{totalUIMoves}</span>
            </div>
        </div>
    );
};

const mapStateToProp = state => {
    return {
        currentTime: state.currentTime,
        timerResponse: state.timerResponse
    };
};

export default connect(mapStateToProp, null)(CombosAndMoves);
