import { combineReducers } from "redux";
import { toggleNavigation, playAudio, aboutModal, theme } from "./toggleReducer";
import { currentTime, timerResponse } from "./timerReducer";
import { configWorkout } from "./configWorkoutReducer";
import { configInputs } from "./configInputsReducer";
import { aboutModalData } from "./aboutModalReducer";
import { boxingPrimer, searchSuggestion, currentPrimerMove } from "./boxingPrimer";
import { comboGenrateResponse } from "./comboGenrateResponse";
import { bookMarkModalReducer } from "./bookMarkModalReducer";
import { shareModalReducer } from "./shareModalReducer";
import { shareToggleReducer } from "./shareToggleReducer";

export default combineReducers({
    playAudio,
    aboutModal,
    toggleNavigation,
    currentTime,
    timerResponse,
    comboGenrateResponse,
    bookMarkModalReducer,
    shareModalReducer,
    shareToggleReducer,
    configWorkout,
    aboutModalData,
    configInputs,
    boxingPrimer,
    currentPrimerMove,
    searchSuggestion,
    theme
});
